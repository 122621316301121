import {
  TbLayoutDashboardFilled,
  TbChartAreaLineFilled,
  TbAlertHexagonFilled,
  TbSquarePercentage,
  TbChartBubble,
  TbCircles,
} from "react-icons/tb";
import {
  MdMotionPhotosPaused,
  MdAccountBalance,
  MdAutoAwesome,
  MdBusiness,
  MdSecurity,
} from "react-icons/md";
import {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useMemo,
  useRef,
} from "react";
import {
  RiPresentationFill,
  RiFolderChartLine,
  RiListIndefinite,
} from "react-icons/ri";
import OndemandVideoTwoToneIcon from "@mui/icons-material/OndemandVideoTwoTone";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import SupportTwoToneIcon from "@mui/icons-material/SupportTwoTone";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import RocketTwoToneIcon from "@mui/icons-material/RocketTwoTone";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { IoFilter, IoReaderOutline } from "react-icons/io5";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import useMediaQuery from "@mui/material/useMediaQuery";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SettingsIcon from "@mui/icons-material/Settings";
import TimelineContent from "@mui/lab/TimelineContent";
import { useDispatch, useSelector } from "react-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PercentIcon from "@mui/icons-material/Percent";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TimelineItem from "@mui/lab/TimelineItem";
import { PiMagicWandFill } from "react-icons/pi";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import TimelineDot from "@mui/lab/TimelineDot";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import MapIcon from "@mui/icons-material/Map";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router";
import Avatar from "@mui/material/Avatar";
import { GrUpdate } from "react-icons/gr";
import Timeline from "@mui/lab/Timeline";
import { FaMagic } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import { useSnackbar } from "notistack";
import { TbPlug } from "react-icons/tb";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import _ from "underscore";

import {
  setIsAccountSelectOverlayOpen,
  setIsFirstLoginOverlayOpen,
  setIsUpdatesOverlayOpen,
  setPlanExpiredShow,
  setOnBoardingList,
  setJoyRideStatus,
} from "../../store/slices/global";
import {
  setStageLoadingText,
  setRefreshBalance,
  toggleShowBanner,
  setTestLoading,
  setIsLoading,
} from "../../store/slices/appmain";
import {
  thinScrollbarStyle,
  removeAllCookies,
  isPlanExpired,
} from "../../Helper/data";
import {
  setMainDrawer,
  setProfile,
  setAddFrom,
} from "../../store/slices/settings";
import { setMaintenanceMode, toggleDevMode } from "../../store/slices/common";
import initialData, { updateLanguage } from "../../Helper/data";
import { setSteps, startTour } from "../../store/slices/tour";
import OnBoardingStepper from "../Stepper/onBoardingStepper";
import CalendlyOverlay from "../Overlay/CalendalyOverlay";
import { formatTimestamp } from "../../Helper/functions";
import HeaderIconView from "./Component/HeaderIconView";
import { Images, Color, Constant } from "../../Helper";
import DatasetHeader from "../Header/DatasetHeader";
import UpgradePlanCard from "./../UpgradePlanCard";
import CustomModal from "./../Model/CustomModal";
import ComponentLoader from "../ComponentLoader";
import EndPoints from "../../APICall/EndPoints";
import authSlice from "../../store/slices/auth";
import packageJson from "../../../package.json";
import CustomLink from "./../CustomLink";
import { queryClient } from "../../App";
import APICall from "../../APICall";
import i18n from "../../i18n";
import Icon from "../Icon";

const iconStyle = {
  fontSize: "1.2rem",
};

const settingsMenu = [
  {
    id: 13,
    title: "Subscription",
    icon: (
      <Icon
        icon={<BsFillRocketTakeoffFill />}
        fontSize={"1.3rem"}
        color="inherit"
      ></Icon>
    ),
    divider: true,
    url: "/settings/subscription",
  },

  {
    id: 3,
    title: "Give Feedback",
    icon: <FeedbackIcon sx={iconStyle} />,
  },
  {
    id: 4,
    title: "Logout",
    icon: <LogoutIcon sx={iconStyle} />,
  },
];

const generalSettingMenu = [
  {
    id: 0,
    title: "Personal",
    icon: <PermIdentityIcon sx={iconStyle} />,
    divider: false,
    url: "/settings/personal",
  },
  {
    id: 1,
    title: "Security",
    icon: (
      <Icon icon={<MdSecurity />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
    divider: true,
    url: "/settings/security",
  },
  {
    id: 2,
    title: "Company Settings",
    icon: <SettingsIcon sx={iconStyle} />,
    divider: false,
    url: "/settings/general",
  },
  {
    id: 3,
    title: "Users",
    icon: <PeopleAltIcon sx={iconStyle} />,
    divider: true,
    url: "/settings/users",
  },
  {
    id: 4,
    title: "Bank & Integrations",
    icon: <Icon icon={<TbPlug />} fontSize={"1.3rem"} color="inherit"></Icon>,
    divider: false,
    url: "/settings/Integrations",
  },
  {
    id: 5,
    title: "datasets",
    icon: (
      <Icon icon={<MdBusiness />} fontSize={"1.3rem"} color="inherit"></Icon>
    ),
    divider: true,
    url: `/settings/${initialData?.path?.organizations}`,
  },

  {
    id: 8,
    title: "Rule_plural",
    icon: <PiMagicWandFill style={iconStyle} />,
    divider: false,
    url: "/settings/rules",
  },
  {
    id: 11,
    title: "Vats",
    icon: <PercentIcon sx={iconStyle} />,
    divider: true,
    url: "/settings/vats",
  },
];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: Constant.DRAWER_WIDTH,
    width: `calc(100% - ${Constant.DRAWER_WIDTH})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const s1280 = useMediaQuery("(min-width:1280px)");

  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );

  const isStartPage =
    window?.location?.pathname?.includes("/dashboard") ||
    window?.location?.pathname === "/";
  const isFinbanAccount = profile?.email?.includes("@finban.io");

  const handleDrawerOpen = () => {
    dispatch(setMainDrawer(true));
  };

  const onClickDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <AppBar
      open={!s1280 && isMainDrawerOpen}
      variant={"div"}
      position="static"
      color="transparent"
      sx={{
        backgroundColor: theme.palette.color.slate[100],
        borderRadius: 0,
        borderBottom: !isStartPage
          ? `1px solid ${theme.palette.color.slate[300]}`
          : 0,
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          px: "0 !important",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: Constant.HEADER_HEIGHT,
        }}
      >
        <Toolbar disableGutters sx={{ display: "contents" }}>
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            sx={{
              display: {
                xs: isMainDrawerOpen || isStartPage ? "none" : "flex",
                s1280: "none",
              },
            }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
          <LogoView />
          <Box sx={{ width: { xs: "0rem", s1920: "4rem" } }}></Box>
          <DatasetHeader />
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 0,
              gap: "1rem",
            }}
          >
            {isFinbanAccount ? (
              <OnBoardingStepper isStartPage={isStartPage} />
            ) : null}
            {(Constant?.isProd ? isFinbanAccount : true) ? <DevPopUp /> : null}
            {isStartPage ? null : (
              <HeaderIconView
                tooltip="dashboard_icon_tooltip"
                icon={<TbLayoutDashboardFilled />}
                onClick={onClickDashboard}
              />
            )}
            <SupportView />
            {isFinbanAccount ? <LogsView /> : null}
            <ProfileOverlay />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;

const LogoView = () => {
  const navigate = useNavigate();
  const s1280 = useMediaQuery("(min-width:1280px)");

  //functions
  const onClickLogo = () => {
    navigate("/dashboard");
  };

  return (
    <Box
      onClick={onClickLogo}
      className={"header-1-step dashboard_overview_4_step"}
      sx={{
        // width: { xs: "3rem", s1280: "4rem" },
        height: "3rem",
        cursor: "pointer",
        mr: { xs: "0rem", s1280: "4rem" },
        ml: "1rem",
      }}
    >
      <img
        src={!s1280 ? Images.finban_logo_small : Images.finban_logo_full}
        height={"100%"}
        width={"100%"}
        alt="App_Logo"
        style={{
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

const SupportView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  //state
  const [helpOpen, setHelpOpen] = useState(false);

  //functions
  const onClickHelp = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  return (
    <>
      <CustomModal
        hideAction
        heading={t("Support")}
        open={helpOpen}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "40rem",
            position: "relative",
            p: "2.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              width: "100%",
              "& svg": {
                color: `${theme.palette.color.slate[700]} !important`,
              },
            }}
          >
            <LinkView
              icon={<RocketTwoToneIcon />}
              text={t("Support_Link_Text_1")}
              href={"https://finban.io/liquiditaetsplanung-mit-finban/"}
            />
            <ChildModal />

            <LinkView
              icon={<SupportTwoToneIcon />}
              text={t("Support_Link_Text_3")}
              href={
                "https://finban.io/documentation/faq-bekannte-probleme-fehler/"
              }
            />
            <LinkView
              icon={<MenuBookTwoToneIcon />}
              text={t("Support_Link_Text_4")}
              href={"//www.finban.io/docs"}
            />
            <LinkView
              icon={<OndemandVideoTwoToneIcon />}
              text={t("Support_Link_Text_5")}
              href={"//www.finban.io/tutorials"}
            />
            <LinkView
              icon={<EmailTwoToneIcon />}
              text={t("Support_Link_Text_6")}
              href={"mailto:support@finban.io"}
            />
            <LinkView
              icon={<MapIcon />}
              text={t("Support_Link_Text_7")}
              href={"https://finban.io/finban-roadmap/"}
            />
            {/* <LinkView
              icon={<QuizTwoToneIcon />}
              text={t("Support_Link_Text_7")}
              href={"//www.finban.io/faq"}
            /> */}
          </Box>
        </Box>
      </CustomModal>
      <HeaderIconView
        tooltip="Support"
        icon={<SupportAgentIcon />}
        onClick={onClickHelp}
        className={"header-3-step dashboard_overview_10_step"}
      />
    </>
  );
};

const LogsView = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const observer = useRef(null);

  //state
  const [helpOpen, setHelpOpen] = useState(false);

  //api
  const getLogs = async (params) => {
    let data = null;
    let url = "";

    if (params?.pageParam) {
      url = url + params?.pageParam;
    } else {
      url = url + EndPoints.action_logs;
      if (params?.page) {
        url = url + `?page=${params?.page}`;
      }
      if (params?.page_size) {
        url = url + `&page_size=${params?.page_size}`;
      }
    }
    await APICall("get", url, null, params?.config).then((response) => {
      if (response.status === 200 && response) {
        data = response?.data;
      }
    });
    return data;
  };

  const getUsers = async () => {
    let result = null;
    await APICall("get", EndPoints.users).then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data.results;
      }
    });
    return result;
  };

  //query
  const Logs = useInfiniteQuery({
    queryKey: ["Logs", { open: helpOpen }],
    queryFn: ({ pageParam = null, signal }) => {
      const params = {
        config: {
          signal,
        },
        pageParam,
        page: 1,
        page_size: 50,
      };
      const result = getLogs(params);
      if (result) {
        return result;
      }
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next ?? undefined;
    },
    select: (data) => {
      const flatData = data?.pages?.map((o1) => o1?.results)?.flat();
      return flatData;
    },
    keepPreviousData: true,
    enabled: !!helpOpen,
  });
  // console.log("🚀 /  / Logs:", Logs?.data);

  const UsersList = useQuery({
    queryKey: ["users"],
    queryFn: ({ signal }) => {
      const result = getUsers();
      if (result) {
        return result;
      }
    },
    enabled: false,
  });

  const UserById = useMemo(() => {
    let data = {};
    if (helpOpen && UsersList?.data) {
      data = _.groupBy(UsersList?.data, ({ uuid }) => uuid);
    }
    return data;
  }, [UsersList?.data, helpOpen]);

  useEffect(() => {
    if (!helpOpen) {
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === "Logs",
      });
    } else {
      if (!UsersList?.data) {
        UsersList?.refetch();
      }
    }
  }, [helpOpen]);

  const observerElem = useCallback(
    (node) => {
      if (Logs?.isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && Logs?.hasNextPage) {
          Logs?.fetchNextPage();
        }
      });
      if (node) observer.current?.observe(node);
    },
    [Logs?.isFetching, Logs?.hasNextPage]
  );

  //functions
  const onClickHelp = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  const getIcon = (log, theme) => {
    let icon = (
      <MdAutoAwesome
        style={{
          fontSize: "1.25rem",
          color: theme.palette.color.white,
        }}
      />
    );
    let color = "amber";
    if (log?.target?.type === "dataset") {
      icon = (
        <MdBusiness
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "scenario") {
      icon = (
        <TbChartAreaLineFilled
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (["Category", "Bank category"].includes(log?.target?.type)) {
      icon = (
        <LocalOfferIcon
          style={{
            fontSize: "1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (["data source", "account"].includes(log?.target?.type)) {
      icon = (
        <TbPlug
          style={{
            fontSize: "1.2rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (["report widget", "report dashboard"].includes(log?.target?.type)) {
      icon = (
        <TbChartBubble
          style={{
            fontSize: "1.2rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (["transaction", "account balance"]?.includes(log?.target?.type)) {
      icon = (
        <MdAccountBalance
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "state") {
      icon = (
        <TbCircles
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "tax") {
      icon = (
        <PercentIcon
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "cost unit") {
      icon = (
        <RiFolderChartLine
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "contact") {
      icon = (
        <IoReaderOutline
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "tenant user") {
      icon = (
        <PeopleAltIcon
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "transaction rule") {
      icon = (
        <PiMagicWandFill
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "loan pause duration") {
      icon = (
        <MdMotionPhotosPaused
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "loan interest rate") {
      icon = (
        <TbSquarePercentage
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "data filter") {
      icon = (
        <IoFilter
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "recurring rule") {
      icon = (
        <FaMagic
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.target?.type === "FinAPI bank connection") {
      icon = (
        <GrUpdate
          style={{
            fontSize: "1.1rem",
            color: theme.palette.color.white,
          }}
        />
      );
    }
    if (log?.data?.error) {
      icon = (
        <TbAlertHexagonFilled
          style={{
            fontSize: "1.55rem",
            color: theme.palette.color.white,
          }}
        />
      );
      color = "red";
    }
    if (log?.verb === "created") {
      color = "slate";
    }
    if (log?.verb === "updated") {
      color = "sky";
    }
    if (log?.verb === "rule applied") {
      color = "purple";
    }
    if (log?.verb === "failed to applied") {
      color = "red";
    }
    return (
      <TimelineDot
        sx={{
          width: "36px",
          height: "36px",
          backgroundColor: theme.palette.color[color][400],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "none",
        }}
      >
        {icon}
      </TimelineDot>
    );
  };

  const logs = Logs?.data || [];
  return (
    <>
      <CustomModal
        hideAction
        heading={t("Logs")}
        open={helpOpen}
        onClose={handleClose}
        PaperPropsSx={{
          m: 0,
          top: 0,
          right: 0,
          maxHeight: "none",
          height: "100%",
          borderRadius: "2px",
          position: "fixed",
        }}
      >
        <Timeline
          position="right" // Align all items to the right
          sx={{
            width: "40rem",
            height: "100%",
            px: "2rem",
            pb: "2rem",
            position: "relative",
            borderRadius: 2,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems:
              Logs?.isFetching && !Logs?.isFetchingNextPage
                ? "center"
                : "flex-start",
            justifyContent:
              Logs?.isFetching && !Logs?.isFetchingNextPage
                ? "center"
                : "flex-start",
            my: 0,
            ...theme.thinScrollBar, // Apply the custom scrollbar from the theme
          }}
        >
          {Logs?.isFetching && !Logs?.isFetchingNextPage ? (
            <ComponentLoader loading hideNoDataPlaceholder height="100%" />
          ) : (
            logs?.map((log) => {
              const user = UserById?.[log?.actor?.uuid]?.[0];
              const _user_name = `${user?.first_name || ""} ${user?.last_name || ""}`;
              const user_name = _user_name?.trim("") ? _user_name : "System";
              const message = `${log?.verb} ${t(log?.action_object?.type)} ${log?.action_object?.title ? `"${log?.action_object?.title}"` : ""} ${log?.action_object && log?.target ? "to" : ""} ${log?.target?.type || ""} ${log?.target?.title ? `"${log?.target?.title}"` : ""}`;
              const capitalizedMessage =
                message?.charAt(0).toUpperCase() + message?.slice(1);
              const tooltip = `${user_name} ${capitalizedMessage}`;
              return (
                <TimelineItem
                  key={log.id}
                  sx={{
                    minHeight: "auto",
                    width: "100%",
                    "&::before": {
                      content: "unset",
                    },
                  }}
                >
                  <TimelineSeparator>
                    {getIcon(log, theme)}

                    <TimelineConnector
                      sx={{
                        bgcolor: theme.palette.color.slate[200],
                        height: "2rem",
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent sx={{ p: "16px", width: "96%" }}>
                    <Tooltip placement="top" arrow title={tooltip}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          variant="subtitle2"
                          fontWeight={"fontWeightBold"}
                          color="color.mainTitle"
                          sx={{
                            backgroundColor: theme.palette.color.slate[200],
                            borderRadius: theme.borderRadius.borderRadiusMD,
                            px: "0.6rem",
                            py: "0.1rem",
                            mr: "0.5rem",
                            minWidth: "fit-content",
                          }}
                        >
                          {user_name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          fontWeight={"fontWeightBold"}
                          color="color.mainTitle"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                            width: `calc(100% - ${0.6 * user_name?.length}rem)`,
                          }}
                        >
                          {message}
                        </Typography>
                      </Stack>
                    </Tooltip>

                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.color.mainTitle,
                          mr: "1rem",
                        }}
                      >
                        {formatTimestamp(log?.timestamp)}
                      </Typography>
                      {log?.actor?.email ? (
                        <PersonIcon
                          sx={{
                            mr: 1,
                            color: theme.palette.primary.main,
                            fontSize: "1rem",
                          }}
                        />
                      ) : (
                        <MdAutoAwesome
                          style={{
                            fontSize: "1rem",
                            marginRight: "4px",
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}

                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.color.mainTitle,
                        }}
                      >
                        {log?.actor?.email ||
                          t(`logs_actor_system_${log?.verb}`)}
                      </Typography>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              );
            })
          )}

          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {!Logs?.isFetching && Logs?.hasNextPage ? (
              <LoadingButton
                ref={observerElem}
                loading={Logs?.isFetchingNextPage}
                onClick={() => Logs?.fetchNextPage()}
                disabled={Logs?.isFetching}
                sx={{ width: "fit-content", mt: "1rem" }}
              >
                {t("Load More")}
              </LoadingButton>
            ) : null}
          </div>
        </Timeline>
      </CustomModal>
      <HeaderIconView
        tooltip="Logs"
        icon={<RiListIndefinite />}
        onClick={onClickHelp}
        sx={{
          "& svg": {
            fontSize: {
              xs: "1.25rem",
              small: "1.4rem",
            },
          },
          p: { xs: "0.35rem", small: "0.65rem" },
        }}
      />
    </>
  );
};

const ProfileOverlay = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  //redux state
  const settings = useSelector((state) => state.settingsSlice);

  //state
  const [anchorElUser, setAnchorElUser] = useState(null);

  //functions
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e, menu) => {
    setAnchorElUser(null);
    if (menu?.title === "Logout") {
      handleLogout();
    } else if (menu?.title === "Give Feedback") {
      window.location.href = "mailto:support@finban.io";
      e.preventDefault();
    } else {
      navigate(menu?.url);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login");
    enqueueSnackbar(t("Logout_message"), {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  return (
    <>
      <ProfilePopUp
        anchorEl={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
        handleCloseNavMenu={handleCloseNavMenu}
      />

      <HeaderIconView
        tooltip="Open settings"
        className={"header-4-step dashboard_overview_11_step"}
        icon={
          <Avatar
            sx={{
              width: { xs: "2rem", small: "2.7rem" },
              height: { xs: "2rem", small: "2.7rem" },
              color: theme.palette.color.slate[700],
              bgcolor: "transparent",
            }}
            src={settings?.profile?.photo}
          >
            {settings?.profile?.photo || <PersonIcon />}
          </Avatar>
        }
        onClick={handleOpenUserMenu}
        sx={{
          mr: "1rem",
          p: 0,
        }}
      />
    </>
  );
};

const ProfilePopUp = ({
  anchorEl,
  handleCloseUserMenu,
  handleCloseNavMenu,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  //redux state
  const settings = useSelector((state) => state.settingsSlice);

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      container={document.body}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseUserMenu}
      PaperProps={{
        elevation: 8,
      }}
      sx={{
        "& .MuiList-root": {
          width: "20rem !important",
        },
        "& .MuiPaper-root": {
          borderRadius: "1rem",
          ...thinScrollbarStyle,
        },
        "& .MuiDivider-root": {
          borderColor: "rgba(0, 0, 0, 0.08)",
        },
      }}
    >
      <Box sx={{ px: "1rem", mt: "0.5rem", backgroundColor: Color.white }}>
        <Stack>
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: "0.9rem",
                  opacity: 0.9,
                  mr: "0.25rem",
                }}
              >
                Hi,
              </Typography>
              <Typography
                component="span"
                variant="h6"
                sx={{
                  fontWeight: 400,
                  fontSize: "0.9rem",
                }}
              >
                {settings?.profile?.first_name && settings?.profile?.last_name
                  ? settings?.profile?.first_name +
                    " " +
                    settings?.profile?.last_name
                  : settings?.profile?.first_name
                    ? settings?.profile?.first_name
                    : settings?.profile?.last_name
                      ? settings?.profile?.last_name
                      : ""}
              </Typography>
            </Stack>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: "0.9rem",
                opacity: 0.9,
                mr: "0.25rem",
              }}
            >
              {`${t("Version")} ${packageJson?.version}`}
            </Typography>
          </Stack>

          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              fontSize: "0.7rem",
              color: Color.greyText,
              mb: "0.5rem",
            }}
          >
            {settings?.profile?.email}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ px: "1rem", backgroundColor: Color.white }}>
        {generalSettingMenu?.map((setting) => (
          <Box
            key={setting?.id}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MenuItem
              onClick={(e) => handleCloseNavMenu(e, setting)}
              sx={{
                color: Color.black,
                borderRadius: "11px",
                "&:hover": {
                  color: theme.palette.primary.dark,
                  backgroundColor: theme.palette.primary[50],
                },
                "&.MuiButtonBase-root": {
                  px: "0.75rem",
                  py: "0.25rem",
                  minHeight: "2.8rem",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "2.1rem",
                  color: "inherit",
                }}
              >
                {setting?.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(setting?.title)}
                sx={{
                  "& span": {
                    fontSize: "0.9rem",
                  },
                  borderRadius: "11px",
                }}
              />
            </MenuItem>
            {setting?.divider ? (
              <Divider sx={{ m: "0.5rem 2rem !important" }} />
            ) : null}
          </Box>
        ))}
      </Box>
      <SubscriptionView />

      <Box sx={{ px: "1rem", backgroundColor: Color.white }}>
        {settingsMenu?.map((setting) => (
          <Box
            key={setting?.id}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MenuItem
              onClick={(e) => handleCloseNavMenu(e, setting)}
              sx={{
                color: Color.black,
                borderRadius: "11px",
                "&:hover": {
                  color: theme.palette.primary.dark,
                  backgroundColor: theme.palette.primary[50],
                },
                "&.MuiButtonBase-root": {
                  px: "0.75rem",
                  py: "0.25rem",
                  minHeight: "2.8rem",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "2.1rem",
                  color: "inherit",
                }}
              >
                {setting?.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(setting?.title)}
                sx={{
                  "& span": {
                    fontSize: "0.9rem",
                  },
                  borderRadius: "11px",
                }}
              />
            </MenuItem>
            {setting?.divider ? <Divider sx={{ m: "0px 2rem" }} /> : null}
          </Box>
        ))}
      </Box>
    </Menu>
  );
};

const SubscriptionView = () => {
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );

  return (
    <Box
      sx={{
        mx: "1rem",
        mb: "0.5rem",
        width: "90%",
        border: 0,
        backgroundColor: Color.white,
      }}
    >
      <UpgradePlanCard
        title="Upgrade your plan"
        description="plan_upgrade_des"
        buttonText={
          subscription?.nameA !== "Trial" ? "Upgrade now" : "Upgrade today"
        }
      />
    </Box>
  );
};

const LinkView = ({ icon, text, href, onClick }) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        color: Color.themeColor,
        mb: "0.5rem",
        "& .MuiSvgIcon-root": {
          fontSize: { xs: "1.15rem", isTablet: "1.6rem" },
          mr: "0.5rem",
        },
        "& a": {
          textDecoration: "none !important",
        },
      }}
    >
      {icon}
      <CustomLink
        onClick={onClick}
        text={text}
        fontSize={{ xs: "0.95rem", isTablet: "1.3rem" }}
        href={href}
        style={{ ml: "1rem", textDecoration: "none !important" }}
      />
    </Box>
  );
};

function ChildModal() {
  const { t } = useTranslation();
  const calendlyRef = useRef();
  const onClickCalendly = () => {
    calendlyRef.current?.handleOpen();
  };
  return (
    <Fragment>
      <LinkView
        onClick={onClickCalendly}
        icon={
          <Icon
            icon={<RiPresentationFill />}
            fontSize={{ xs: "1.2rem", isTablet: "1.5rem" }}
            style={{
              mr: "0.6rem",
            }}
          ></Icon>
        }
        text={t("Support_Link_Text_2")}
      />

      <CalendlyOverlay ref={calendlyRef} />
    </Fragment>
  );
}

const DevPopUp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isDevMode = useSelector((state) => state.commonSlice?.isDevMode);
  const dataSource = useSelector((state) => state.globalSlice?.dataSource);
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );
  // const onBoardingList = [
  //   {
  //     uuid: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "1. Set-up finban",
  //     title: "1. finban Einrichten",
  //     content_en: "",
  //     content: "<p>Erste Schritte - finban einrichten<br><br></p>",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "f1b3a27b-c795-4e8d-92f8-d271860c1e45",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: 1,
  //         last_modified_user_id: 1,
  //         user: "test007@finban.io",
  //         title_en: "1.1 Connect bank account",
  //         title: "1.1. Bankkonto verbinden",
  //         content_en:
  //           "<p>Firstly, we recommend that you connect a business account and categorise the synchronised transactions.</p>",
  //         content:
  //           "<p>Zun&auml;chst empfehlen wir dir, ein <strong>Gesch&auml;ftskonto zu verbinden</strong> und die synchronisierten Transaktionen zu kategorisieren.</p>",
  //         button_title_en: "Add bank account",
  //         button_title_de: "Bankkonto hinzufügen",
  //         button_id: "open-add-integration-overlay",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //         children: [],
  //         position: 11,
  //       },
  //       {
  //         uuid: "d5097f1e-f776-484b-9b76-cdd8a23ab25b",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "1.6. Connect your accounting or CRM",
  //         title: "1.6. Verbinde deine Buchhaltung oder CRM",
  //         content_en:
  //           "<p>You can add additional data sources with finban. Data sources can be your <strong>accounting</strong> programme, a <strong>CRM</strong> system or Excel / <strong>Google Sheets</strong>.</p>",
  //         content:
  //           "<p>Mit finban kannst du weitere Datenquellen hinzuf&uuml;gen. Datenquellen k&ouml;nnen dein <strong>Buchhaltungsprogramm, </strong>ein <strong>CRM</strong>-System oder Excel / <strong>Google Sheets</strong> sein.&nbsp;</p>",
  //         button_title_en: "Add integration",
  //         button_title_de: "Integration hinzufügen",
  //         button_id: "open-add-integration-overlay",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //         children: [],
  //         position: 16,
  //       },
  //       {
  //         uuid: "e2633784-3dd7-4e1a-acc8-f78ac13468c9",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "1.2. Categorise your bank transactions",
  //         title: "1.2. Kategorisiere deine Banktransaktionen",
  //         content_en:
  //           "<p>Bank transactions are a look into the past. If you organise these, you can use them as a basis for modelling your costs in the future.</p>",
  //         content:
  //           "<p>Banktransaktionen sind ein Blick in die Vergangenheit. Wenn du diese organisierst, kannst du sie als Grundlage f&uuml;r die Modellierung deiner zuk&uuml;nftigen Kosten verwenden.</p>",
  //         button_title_en: "Categorise now",
  //         button_title_de: "Jetzt kategorisieren",
  //         button_id: "list-view-to-cat",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //         children: [],
  //         position: 12,
  //       },
  //       {
  //         uuid: "7e8fc6a8-0f77-4c6c-ac81-f454bae5a4af",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "1.3 Plan expenses",
  //         title: "1.3. Ausgaben planen",
  //         content_en:
  //           "<p>Select an expense category in the planning view and click on the cell for the current month.</p>\r\n<p>Now you can create a recurring entry for marketing costs, for example. Repeat this for all your expense categories.</p>",
  //         content:
  //           "<p>W&auml;hle eine Ausgabenkategorie in der Planungsansicht und klinke in die Zelle des aktuellen Monats.</p>\r\n<p>Jetzt kannst du z.B. eine wiederkehrende Buchung f&uuml;r z.B. Marketingkosten anlegen. Das wiederholst du f&uuml;r all deine Ausgabenkategorien.&nbsp;</p>",
  //         button_title_en: "Go to plan view",
  //         button_title_de: "Zur Planansicht gehen",
  //         button_id: "access-plan-view",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //         children: [],
  //         position: 13,
  //       },
  //       {
  //         uuid: "27b9caf5-f0e4-41d0-8a51-3adedec9e93a",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "1.5 Revenue planning",
  //         title: "1.5. Einnahmen planen",
  //         content_en:
  //           "<p>Once you have planned your expenses, we recommend planning your income. You can do this in various ways:</p>\r\n<ul>\r\n<li>Synchronise quotes and invoices via an accounting tool</li>\r\n<li>Synchronise deals via a CRM tool</li>\r\n<li>Synchronise open items via Excel or Google Sheet</li>\r\n<li>Create deals, offers and invoices in finban</li>\r\n</ul>",
  //         content:
  //           "<p>Sobald Du Deine Ausgaben geplant hast, empfehlen wir die Einnahmen zu planen. Das kannst du auf verschiedene weise machen:</p>\r\n<ul>\r\n<li>Angebote und Rechnungen via Buchhaltungstool synchronisieren</li>\r\n<li>Deals via CRM Tool synchronisieren</li>\r\n<li>Offene Posten via Excel oder Google Sheet synchronisieren</li>\r\n<li>Deals, Angebote und Rechnungen in finban anlegen</li>\r\n</ul>\r\n<p>&nbsp;</p>",
  //         button_title_en: "Access plan view",
  //         button_title_de: "Planung aufrufen",
  //         button_id: "access-plan-view",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //         children: [],
  //         position: 15,
  //       },
  //       {
  //         uuid: "e02c41ff-ff4b-4873-8f9c-83498cec99c9",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "1.7 Understanding transaction types",
  //         title: "1.7. Transaktionstypen verstehen",
  //         content_en: "",
  //         content: "<p>booked / open / budget</p>",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //         children: [],
  //         position: 14,
  //       },
  //       {
  //         uuid: "03e3e6f8-4459-4034-a2dc-21e1ab7e6c3b",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "1.4. Plan your staff",
  //         title: "1.4. Plane Dein Personal",
  //         content_en:
  //           "<p>For many companies, one of the largest cost blocks is personnel. You can easily budget these expenses with finban in the HR module.</p>\r\n<p>Quickly find out what the <strong>actual cost of a hire</strong> is and enjoy the peace of mind that no costs have been overlooked.</p>",
  //         content:
  //           "<p>F&uuml;r viele Unternehmen ist einer der gr&ouml;&szlig;ten Kostenbl&ouml;cke das Personal. Mit finban im Personal-Modul lassen sich diese Ausgaben leicht budgetieren.</p>\r\n<p>Erfahre schnell, wie hoch <strong>die tats&auml;chlichen Kosten f&uuml;r eine Einstellung</strong> sind, und genie&szlig;e die Gewissheit, dass keine Kosten &uuml;bersehen wurden.</p>",
  //         button_title_en: "Personal planen",
  //         button_title_de: "Staff planning",
  //         button_id: "access-staff-view",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "aeb69d50-4f3d-4507-bdce-23f3ee98ce06",
  //         children: [],
  //         position: 14,
  //       },
  //     ],
  //     position: 10,
  //   },
  //   {
  //     uuid: "ada05877-c5b8-4d8a-910b-adbfb608e623",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "",
  //     title: "2. Listenansicht",
  //     content_en: "",
  //     content: "",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "de51ca03-a809-42af-afbc-e50f5711b683",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "2.1 Listen A",
  //         content_en: "",
  //         content:
  //           '<p>Powerful features you find in <strong>List view &amp; Bulk categorization</strong> 💪🏼 <br><br>Here you can assign to a collection of similar transactions (e.g. monthly maintenance contracts) to the same category. You can also set how this similarity is calculated: Same titles, Same values, Similar titles, Similar values.<br><br>Here you find the <a href="../../../../../organization/a6ebc086-e6c0-43a4-9e98-56ea916537fb/list/bulk">Bulk Categorization</a> of your default project. <br><br>🛟 Here you find the <a href="https://finban.io/documentation/list/" target="_blank" rel="noopener">List Documentation</a></p>',
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "ada05877-c5b8-4d8a-910b-adbfb608e623",
  //         children: [],
  //         position: 21,
  //       },
  //       {
  //         uuid: "0ae8a0b2-74f2-480c-86e2-a36e10eca7e4",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "2.2. Listen B",
  //         content_en: "",
  //         content: "<p>qwer</p>",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "ada05877-c5b8-4d8a-910b-adbfb608e623",
  //         children: [],
  //         position: 22,
  //       },
  //     ],
  //     position: 20,
  //   },
  //   {
  //     uuid: "e376832b-b3b6-4c8c-8724-d4a732d1f3fd",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "",
  //     title: "3. Szenarien planen",
  //     content_en: "",
  //     content: "<p><br><br><br></p>",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "8d89fa28-cb39-4726-a9ea-905a7e29ffdc",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "3.1. Verfügbare Szenarien nutzen",
  //         content_en: "",
  //         content: "<p>qwer</p>",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "e376832b-b3b6-4c8c-8724-d4a732d1f3fd",
  //         children: [],
  //         position: 31,
  //       },
  //       {
  //         uuid: "fbff0d42-55e4-4815-a650-6a1b78b6cff0",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "3.2. Eigene Szenarien anlegen",
  //         content_en: "",
  //         content: "",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "e376832b-b3b6-4c8c-8724-d4a732d1f3fd",
  //         children: [],
  //         position: 32,
  //       },
  //     ],
  //     position: 30,
  //   },
  //   {
  //     uuid: "5c9ed26d-9e73-4ede-b221-a6c8012a350c",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "",
  //     title: "4. Dashboards",
  //     content_en: "",
  //     content: "",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "2bfb7b9f-2224-4735-a632-bdf6db7af6f3",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "4.1. Dashboards anlegen",
  //         content_en: "",
  //         content: "<p>qwert</p>",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "5c9ed26d-9e73-4ede-b221-a6c8012a350c",
  //         children: [],
  //         position: 41,
  //       },
  //       {
  //         uuid: "79720f0b-010d-4fcf-8a14-38342ab861c1",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "4.2. Widgets hinzufügen",
  //         content_en: "",
  //         content: "<p>qwer</p>",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "5c9ed26d-9e73-4ede-b221-a6c8012a350c",
  //         children: [],
  //         position: 42,
  //       },
  //     ],
  //     position: 40,
  //   },
  //   {
  //     uuid: "1b64bf74-9857-4a55-aa1e-75cb31bbf044",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "",
  //     title: "5. Weitere Funktionalitäten",
  //     content_en: "",
  //     content: "",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "9fc6514c-449b-4474-8a11-857540978c2b",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "5.1 Organisationen",
  //         content_en: "",
  //         content:
  //           '<p>With finban you can manage multiple <strong>organisations</strong>. For example, if you want to view individual <strong>companies</strong> or <strong>departments</strong> separately from each other, you simply create a new organisation and name it after the company or departments. <br><br>✏️ To Edit, Add or Delete them, go to <a href="../../../../../settings/organizations">Organizations</a><br><br>🛟 Here your find our <a target="_blank">Organizations Documentation</a></p>',
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 2,
  //         parent: "1b64bf74-9857-4a55-aa1e-75cb31bbf044",
  //         children: [],
  //         position: 51,
  //       },
  //       {
  //         uuid: "d362b99a-f74d-402f-833d-ae18bde19323",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "",
  //         title: "5.2. Kategorien",
  //         content_en: "",
  //         content: "",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "1b64bf74-9857-4a55-aa1e-75cb31bbf044",
  //         children: [],
  //         position: 52,
  //       },
  //       {
  //         uuid: "3ff368de-9fbc-401f-bd6f-58472096835b",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "5.3. Rules",
  //         title: "5.3. Regeln",
  //         content_en: "<p>5.3. Rules</p>",
  //         content: "<p>5.3. Regeln</p>",
  //         button_title_en: "Call up rules",
  //         button_title_de: "Regeln aufrufen",
  //         button_id: "access-rules-view",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "1b64bf74-9857-4a55-aa1e-75cb31bbf044",
  //         children: [],
  //         position: 53,
  //       },
  //     ],
  //     position: 50,
  //   },
  //   {
  //     uuid: "bddbb8af-390d-431e-ada9-da6bafd992a5",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "6. more tips and tricks for various modules",
  //     title: "6. Mehr Tipps und Tricks für verschiedene Module",
  //     content_en: "",
  //     content: "<p>qwer&nbsp;&nbsp;</p>",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "4e4bb3fd-357e-405f-913d-d8317544f79f",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "6.1 Personnel planning",
  //         title: "6.1. Personalplanung",
  //         content_en: "",
  //         content: "",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "bddbb8af-390d-431e-ada9-da6bafd992a5",
  //         children: [],
  //         position: 61,
  //       },
  //       {
  //         uuid: "5296baf9-5269-4f75-b054-106b1884d797",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "6.2 Loan management",
  //         title: "6.2. Darlehensverwaltung",
  //         content_en: "",
  //         content: "",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "bddbb8af-390d-431e-ada9-da6bafd992a5",
  //         children: [],
  //         position: 62,
  //       },
  //     ],
  //     position: 60,
  //   },
  //   {
  //     uuid: "930d62b7-2573-487b-9024-69edb46d33d8",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "7. Integrationen",
  //     title: "7. Integrationen",
  //     content_en: "",
  //     content: "<p>qwert</p>",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "9f1902a7-c945-4283-887e-9ca9e3f96b51",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "7.1 lexoffice",
  //         title: "7.1 lexoffice",
  //         content_en: "",
  //         content: "",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "930d62b7-2573-487b-9024-69edb46d33d8",
  //         children: [],
  //         position: 71,
  //       },
  //       {
  //         uuid: "4e664c7d-428e-4478-bff6-dc1c9a6b3520",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "7.2. SevDesk",
  //         title: "7.2. SevDesk",
  //         content_en: "",
  //         content: "<p>qwert</p>",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "930d62b7-2573-487b-9024-69edb46d33d8",
  //         children: [],
  //         position: 72,
  //       },
  //       {
  //         uuid: "42ace5db-5eb7-4eaf-901b-a8c35cbff030",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "7.3. Google Sheet",
  //         title: "7.3. Google Sheet",
  //         content_en: "",
  //         content: "<p>wer</p>",
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "930d62b7-2573-487b-9024-69edb46d33d8",
  //         children: [],
  //         position: 73,
  //       },
  //     ],
  //     position: 70,
  //   },
  //   {
  //     uuid: "c722b31a-4520-4b15-a8f0-967b8b2964e2",
  //     creation_date: "2024-09-12T09:19:15.098426+02:00",
  //     last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //     creation_user_id: null,
  //     last_modified_user_id: null,
  //     user: "test007@finban.io",
  //     title_en: "8. Frequently asked questions",
  //     title: "8. Häufig gestellte Fragen",
  //     content_en: "",
  //     content: "",
  //     button_title_en: "",
  //     button_title_de: "",
  //     button_id: "",
  //     category: "onboarding",
  //     checked: false,
  //     priority: 1,
  //     parent: null,
  //     children: [
  //       {
  //         uuid: "d16646aa-ec8c-413e-bf51-538035555393",
  //         creation_date: "2024-09-12T09:19:15.098426+02:00",
  //         last_modified_date: "2024-09-12T09:19:15.098426+02:00",
  //         creation_user_id: null,
  //         last_modified_user_id: null,
  //         user: "test007@finban.io",
  //         title_en: "How to change the language",
  //         title: "So änderst du die Sprache",
  //         content_en:
  //           '<p>1) Call up the personal settings: <a href="https://app.finban.io/settings/personal">https://app.finban.io/settings/personal</a></p>\r\n<p>2) Select the language</p>',
  //         content:
  //           '<p>1) Rufe die pers&ouml;nlichen Einstellungen auf: <a href="https://app.finban.io/settings/personal">https://app.finban.io/settings/personal</a><br>2) W&auml;hle die Sprache aus</p>',
  //         button_title_en: "",
  //         button_title_de: "",
  //         button_id: "",
  //         category: "onboarding",
  //         checked: false,
  //         priority: 1,
  //         parent: "c722b31a-4520-4b15-a8f0-967b8b2964e2",
  //         children: [],
  //         position: 81,
  //       },
  //     ],
  //   },
  // ];
  //api
  const updateProfile = async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  };

  const getTodos = async () => {
    await APICall("get", EndPoints.todos).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(
          setOnBoardingList(
            response.data?.results.filter(
              (o1) => o1.category?.toLowerCase() === "onboarding"
            )
          )
        );
        // dispatch(setOnBoardingList(onBoardingList));
      }
    });
  };

  //functions
  const refetchAllTranslations = () => {
    const promises = i18n.options.supportedLngs
      ?.filter((lang) => lang !== "cimode")
      ?.map((lang) => {
        return i18n.reloadResources(lang);
      });

    Promise.all(promises).then(() => {
      dispatch(setRefreshBalance(Date.now()));
    });
  };

  const onClickFunction = async (type, popupState) => {
    popupState?.close();
    if (type === "browser_cache") {
      removeAllCookies();
      if ("caches" in window) {
        const cacheKeys = await window.caches.keys();
        await Promise.all(
          cacheKeys.map((key) => {
            window.caches.delete(key);
          })
        );
      }
      setTimeout(() => {
        window.location.replace(window.location.href);
      }, 500);
    }

    if (type === "dataset_header" || type === "reports") {
      let joyRideStatusFilter = joyRideStatus?.filters;
      if (joyRideStatusFilter) {
        let filterObj = {
          ...joyRideStatus,
          data_type: "transaction",
          filters: { ...joyRideStatusFilter, [type]: false },
        };
        setTimeout(() => {
          dispatch(setSteps({ key: type, t }));
          dispatch(startTour());
          dispatch(setJoyRideStatus(filterObj));
        }, 0);
      }
    }

    if (type === "reset_cache") {
      queryClient.resetQueries();
    }
    if (type === "reset_integration_cache") {
      let options = {
        predicate: (query) => query.queryKey[0] === "integrations",
      };
      queryClient.resetQueries(options);
    }
    if (type === "test_loading") {
      dispatch(setTestLoading());
    }
    if (type === "open_first_login_overlay") {
      dispatch(setIsFirstLoginOverlayOpen(true));
    }
    if (type === "open_updates_overlay") {
      dispatch(setIsUpdatesOverlayOpen(true));
    }
    if (type === "open_expired_plan_overlay") {
      dispatch(
        setPlanExpiredShow({
          title: "plan_expired_overlay_title",
          desc: "plan_expired_overlay_desc",
        })
      );
    }
    if (type === "open_maintenance_page") {
      dispatch(setMaintenanceMode(true));
    }
    if (type === "open_account_overlay") {
      dispatch(
        setAddFrom({
          integration_id: dataSource?.find((o1) => o1.type === 12)?.uuid,
          targetUrl: window.location.pathname,
        })
      );
      dispatch(
        setIsAccountSelectOverlayOpen({ open: true, isBankAccountView: true })
      );
    }
    if (type === "first_login") {
      dispatch(setProfile({ ...profile, first_login: true }));
      updateProfile({ first_login: true });
    }

    if (type === "language") {
      let obj = { locale: profile.locale !== "de_DE" ? "de_DE" : "en_US" };
      updateLanguage(obj.locale);
      dispatch(
        setProfile({
          ...profile,
          ...obj,
        })
      );
      updateProfile(obj);
    }
    if (type === "beta_enabled") {
      let obj = { beta_enabled: !profile.beta_enabled };
      dispatch(
        setProfile({
          ...profile,
          ...obj,
        })
      );
    }
    if (type === "toggleDevMode") {
      dispatch(toggleDevMode());
    }
    if (type === "user_role") {
      let obj = { is_owner: !profile.is_owner };
      dispatch(
        setProfile({
          ...profile,
          ...obj,
        })
      );
    }
    if (type === "test_spin") {
      dispatch(setIsLoading(true));
      setTimeout(() => {
        dispatch(setIsLoading(false));
      }, 5000);
    }

    if (type === "test_stage_loader") {
      dispatch(setStageLoadingText("test"));
      setTimeout(() => {
        dispatch(setStageLoadingText(null));
      }, 5000);
    }
    if (type === "test_banner") {
      dispatch(toggleShowBanner());
    }

    if (type === "theme_log") {
      console.log("theme==>>", theme);
    }
    if (type === "sentry_error") {
      throw new Error("testing error");
    }
    if (type === "fetch_todos") {
      getTodos();
    }
    if (type === "fetch_translations") {
      refetchAllTranslations();
    }
  };

  const options = [
    {
      label: "Clear browser cache, cookies and reload",
      action: "browser_cache",
    },
    { label: "Reset all query Cache", action: "reset_cache" },
    {
      label: "Reset Integrations query Cache",
      action: "reset_integration_cache",
    },
    { label: "Test Banner", action: "test_banner" },
    { label: "Test Loading", action: "test_loading" },
    { label: "Test Spinner", action: "test_spin" },
    { label: "Test Stage Loader", action: "test_stage_loader" },
    { label: "Open Maintenance Page", action: "open_maintenance_page" },
    { label: "Open Expired Plan Overlay", action: "open_expired_plan_overlay" },
    { label: "Open First Login Overlay", action: "open_first_login_overlay" },
    { label: "Open Updates Overlay", action: "open_updates_overlay" },
    { label: "Open Account Overlay", action: "open_account_overlay" },
    { label: `Language: ${profile?.locale}`, action: "language" },
    {
      label: `Beta: ${profile?.beta_enabled?.toString()}`,
      action: "beta_enabled",
    },
    {
      label: `Dev Mode : ${isDevMode?.toString()}`,
      action: "toggleDevMode",
    },
    {
      label: `Is owner: ${profile?.is_owner?.toString()}`,
      action: "user_role",
    },
    { label: "Reset first login", action: "first_login" },
    { label: "Reset header joyride", action: "dataset_header" },
    { label: "Reset reports joyride", action: "reports" },
    { label: "Report Sentry error", action: "sentry_error" },
    { label: "Themes log", action: "theme_log" },
    { label: "Fetch Todos", action: "fetch_todos" },
    { label: "Fetch Translations", action: "fetch_translations" },
  ];

  return (
    <PopupState
      variant="popper"
      id="demo-mutiple-checkbox"
      popupId="demo-popup-popover"
      sx={{
        zIndex: 1500,
      }}
    >
      {(popupState) => (
        <>
          <HeaderIconView
            {...bindTrigger(popupState)}
            tooltip=""
            icon={<TbLayoutDashboardFilled />}
            sx={{
              position: "absolute",
              top: "6.5rem",
              right: "1rem",
              zIndex: 1500,
            }}
          />
          <Popover
            {...bindPopover(popupState)}
            sx={{
              zIndex: 1500,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: 1.5,
                  boxShadow: Constant.OverlayBoxShadow,
                },
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "fit-content",
                minHeight: "3.125rem",
                width: "fit-content",
                minWidth: "18.75rem",
                position: "relative",
                p: "1rem",
              }}
            >
              {options.map((option) => (
                <Typography
                  key={option?.action}
                  variant="div"
                  sx={{ cursor: "pointer" }}
                  onClick={() => onClickFunction(option?.action, popupState)}
                >
                  {option?.label}
                </Typography>
              ))}
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
