import {
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  ComposedChart,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  Text,
  Line,
} from "recharts";
import { Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useEffect } from "react";

import {
  formatDateToLocal,
  formatAmount,
  truncate,
} from "../../../Helper/data";
import CustomTooltip from "../../../components/Charts/CustomTooltip";
import { Fonts } from "../../../Helper";
import store from "../../../store";
import theme from "../../../theme";

const strokeDasharray = [
  "8 4",
  "2 2",
  "5 5 1 2",
  "10 10 10",
  "20 5 8 10",
  "10 2 1 20",
  "8 5 8 20",
  "8 5 8 5 8 20",
  "10 4 4 3 4 10",
];

const Chart = ({
  chart_data,
  apiParams,
  setIsInfoShowed,
  dataset,
  skeletonSx,
  Xaxis,
  Yaxis,
  margin = {
    top: 60,
    right: 40,
    left: 10,
    bottom: 10,
  },
  tick = {
    fontSize: "0.75rem",
    fontWeight: 600,
    fontFamily: Fonts.Text,
  },
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //redux
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const testLoading = useSelector((state) => state.appSlice.testLoading);

  const [chartKeys, setChartKeys] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  const tickFormatter = (tick) => {
    let value = formatAmount({
      amount: String(tick ?? 0),
      dataset,
    });
    return value;
  };

  const tickFormatterX = (tick) => {
    let value = format(new Date(tick), "MMM yy");
    return value;
  };

  const gradientOffset = useCallback(
    (key = "Liquidity") => {
      if (chart_data?.data) {
        const dataMax = Math.max(...chart_data?.data?.map((i) => i[key]));
        const dataMin = Math.min(...chart_data?.data?.map((i) => i[key]));
        if (dataMax <= 0) {
          return 0;
        } else if (dataMin >= 0) {
          return 1;
        } else {
          let number = dataMax / (dataMax - dataMin);

          if (!isNaN(number)) {
            return number;
          }
        }
      }
    },
    [chart_data?.data]
  );

  function sumAmounts(data, keys) {
    let sum = 0;
    for (const key in data) {
      if (keys?.includes(key)) {
        sum = sum + (data?.[key] || 0);
      }
    }
    return sum;
  }

  useEffect(() => {
    if (
      !chart_data?.isFetching &&
      (!chart_data?.data?.length || chart_data?.data?.length === 0)
    ) {
      if (setIsInfoShowed) setIsInfoShowed(true);
    } else {
      if (setIsInfoShowed) setIsInfoShowed(false);
    }

    if (
      !chart_data?.isFetching &&
      chart_data?.data?.length > 0 &&
      apiParams?.length > 0
    ) {
      let chart_keys = [];
      let limit = null;
      apiParams?.forEach((entry, index) => {
        const accountsById = store?.getState()?.globalSlice.accountsById;
        const dataSourceById = store?.getState()?.globalSlice.dataSourceById;
        const account = accountsById?.[entry?.accountID]?.[0];
        const data_source = dataSourceById?.[account?.data_source]?.[0];
        const _strokeDasharray =
          apiParams?.length === 1 ? "0" : strokeDasharray[index];
        chart_keys.push({
          dataKey: account?.uuid,
          key: account?.uuid,
          title:
            data_source?.title || account?.bank_details?.title || account?.name,
          strokeDasharray: _strokeDasharray,
          hideTitle: true,
          icon: (
            <TooltipItemTitle
              stroke={theme.palette.primary.main}
              title={
                data_source?.title ||
                account?.bank_details?.title ||
                account?.name
              }
              strokeDasharray={_strokeDasharray}
              fontWeight={500}
            />
          ),
        });
      });
      if (dataset?.uuid === "overview") {
        const filterData = accounts?.filter(
          (o1) => o1?.show_limit && o1.currency === dataset?.currency
        );
        if (filterData?.length > 0) {
          const total = filterData?.reduce(
            (total, item) => parseFloat(total) + parseFloat(item?.limit ?? 0),
            0
          );
          limit = -Math.abs(total);
          chart_keys.push({
            key: "account_chart_limit",
            dataKey: "limit",
            title: "account_chart_limit",
            strokeDasharray: "0",
            hideTitle: true,
            value: limit,
            stroke: theme.palette.color.red[300],
            icon: (
              <TooltipItemTitle
                stroke={theme.palette.color.red[300]}
                title={"account_chart_limit"}
                fontWeight={500}
              />
            ),
          });
        }
      } else {
        const filterData = accounts?.filter(
          (o1) => o1?.show_limit && o1?.datasets?.includes(dataset?.uuid)
        );
        if (filterData?.length > 0) {
          const total = filterData?.reduce(
            (total, item) => parseFloat(total) + parseFloat(item?.limit ?? 0),
            0
          );
          limit = -Math.abs(total);

          chart_keys.push({
            key: "account_chart_limit",
            dataKey: "limit",
            title: "account_chart_limit",
            strokeDasharray: "0",
            hideTitle: true,
            value: limit,
            stroke: theme.palette.color.red[300],
            icon: (
              <TooltipItemTitle
                stroke={theme.palette.color.red[300]}
                title={"account_chart_limit"}
                fontWeight={500}
              />
            ),
          });
        }
      }
      let data = [];
      chart_data?.data?.forEach((entry) => {
        const accountKeys = chart_keys
          ?.filter(
            (key) =>
              key !== "due_date" && key !== "month" && key !== "total_key"
          )
          ?.map((o1) => o1?.dataKey);

        data.push({
          ...entry,
          limit,
          total_key: sumAmounts(entry, accountKeys),
        });
      });
      if (apiParams?.length > 1) {
        chart_keys.push({
          key: "total_key",
          dataKey: "total_key",
          title: "total_key",
          showTopSeparator: true,
          strokeDasharray: "0",
          hideTitle: true,
          isBold: true,
          stroke: theme.palette.primary[500],
          icon: <TooltipItemTitle stroke={theme.palette.primary.main} />,
        });
      }

      setChartData(data);
      setChartKeys(chart_keys);
    }
  }, [
    accounts,
    apiParams,
    chart_data?.data,
    chart_data?.isFetching,
    dataset?.currency,
    dataset?.uuid,
    setIsInfoShowed,
    t,
    theme.palette.color.red,
    theme.palette.primary,
  ]);

  return (
    <>
      {chart_data?.isFetching || testLoading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{ width: "100%", height: "100%", ...skeletonSx }}
        />
      ) : chartData?.length > 0 && apiParams?.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={chartData} margin={margin} overflow="visible">
            <CartesianGrid
              strokeDasharray="1"
              horizontal={false}
              vertical={false}
            />
            <XAxis
              dataKey="due_date"
              tickLine={false}
              stroke={theme.palette.color.slate[400]}
              tick={tick}
              tickFormatter={tickFormatterX}
              interval={2}
              // ticks={["2023-02","2023-05","2023-07"]}
              {...Xaxis}
            />
            <YAxis
              tickLine={false}
              stroke={theme.palette.color.slate[400]}
              tick={tick}
              tickFormatter={tickFormatter}
              includeHidden
              {...Yaxis}
            />
            <ReferenceLine
              y={0}
              fill={theme.palette.color.slate[200]}
              strokeWidth={1}
            />
            <ReferenceLine
              x={format(new Date(), "yyyy-MM")}
              stroke={theme.palette.color.slate[200]}
              strokeWidth={1}
              label={
                <CustomizedLabel
                  label={formatDateToLocal(new Date(), "MMM yy")}
                />
              }
            />
            {chartKeys?.map((item) => {
              if (item?.dataKey === "limit" || item?.dataKey === "total_key") {
                return (
                  <Line
                    key={item?.dataKey}
                    dataKey={item?.dataKey}
                    stroke={item?.stroke}
                    strokeWidth={1}
                    dot={false}
                  >
                    <LabelList
                      content={
                        <CustomizedLineLabel
                          title={t(item?.title)}
                          fill={item?.stroke}
                        />
                      }
                    />
                  </Line>
                );
              } else {
                return (
                  <React.Fragment key={item?.dataKey}>
                    <defs>
                      <linearGradient
                        id={`${item?.dataKey}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2={"1"}
                      >
                        <stop offset={0} stopColor={"green"} stopOpacity={1} />
                        <stop
                          offset={gradientOffset(item?.dataKey)}
                          stopColor={"green"}
                          stopOpacity={0}
                        />
                        <stop
                          offset={gradientOffset(item?.dataKey)}
                          stopColor={theme.palette.color.red[500]}
                          stopOpacity={0}
                        />
                        <stop
                          offset={1}
                          stopColor={theme.palette.color.red[500]}
                          stopOpacity={1}
                        />
                      </linearGradient>
                      <linearGradient
                        id={`${item?.dataKey}_line`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2={"1.09"}
                      >
                        <stop
                          offset={gradientOffset(item?.dataKey)}
                          stopColor={"green"}
                          stopOpacity={1}
                        />
                        <stop
                          offset={gradientOffset(item?.dataKey)}
                          stopColor={theme.palette.color.red[500]}
                          stopOpacity={1}
                        />
                      </linearGradient>
                    </defs>

                    <Area
                      dataKey={item?.dataKey}
                      stroke={`url(#${item?.dataKey}_line)`}
                      fill={`url(#${item?.dataKey})`}
                      dot={false}
                      strokeDasharray={item?.strokeDasharray}
                      fillOpacity={0.1}
                    >
                      <LabelList
                        content={
                          <CustomizedLineLabel
                            title={t(item?.title)}
                            fill={`url(#${item?.dataKey}_line)`}
                          />
                        }
                      />
                    </Area>
                  </React.Fragment>
                );
              }
            })}
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={
                <CustomTooltip
                  barsData={chartKeys}
                  dataset={dataset}
                />
              }
              wrapperStyle={{ zIndex: 1303 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <Typography
          alignSelf={"center"}
          sx={{
            fontSize: "0.87rem",
            color: theme.palette.color.slate[400],
            fontWeight: 500,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("No_Data_Found")}
        </Typography>
      )}
    </>
  );
};

export default Chart;

const CustomizedLabel = ({ viewBox, label }) => {
  const { x, y } = viewBox;
  const rectWidth = 46;
  const rectHeight = 18;
  const rectX = x - rectWidth / 2;
  const rectY = y + rectHeight;

  return (
    <>
      <rect
        x={rectX}
        y={rectY}
        width={rectWidth}
        height={rectHeight}
        fill={theme.palette.primary[500]}
        rx={5}
        z={50}
      />
      <Text
        x={rectX + rectWidth / 2}
        y={rectY - 0.5 + rectHeight / 2}
        fontSize={rectHeight / 2}
        lineHeight={rectHeight / 2}
        fontWeight="bold"
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {label}
      </Text>
    </>
  );
};

const CustomizedLineLabel = (props) => {
  const { x, y, index, title, fill } = props;
  const _title = truncate(title, 20);
  const rectWidth = (_title?.length || 1) * 5.25;
  const rectHeight = 10;
  const rectY = y - rectHeight / 2;
  const transform = x < 60 ? -30 : -60;
  if (index === 1) {
    return (
      <>
        <rect
          x={x + transform}
          y={rectY}
          width={rectWidth}
          height={rectHeight}
          fill={"#ffF"}
        />
        <text
          x={x}
          y={rectY + rectHeight / 2}
          dx={transform}
          fill={fill}
          fontSize={rectHeight}
          fontWeight={600}
          textAnchor="start"
          dominantBaseline="central"
        >
          {_title}
        </text>
      </>
    );
  }
  return null;
};

const TooltipItemTitle = ({
  stroke = "",
  title = "total_key",
  strokeDasharray = "0",
  fontWeight = 800,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      direction={"row"}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "13rem",
      }}
    >
      <span
        style={{
          marginRight: "0.5rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: "0.7rem",
          lineHeight: "0.7rem",
          fontWeight,
          display: "flow-root",
        }}
      >
        {t(title)}
      </span>
      <div
        style={{
          display: "flex",
        }}
      >
        <svg width="30" height="1">
          <line
            x1="0"
            y1="1"
            x2="100"
            y2="1"
            stroke={stroke}
            strokeWidth="2"
            strokeDasharray={strokeDasharray}
          />
        </svg>
      </div>
    </Stack>
  );
};
