import {
  ToggleButtonGroup,
  OutlinedInput,
  ToggleButton,
  Typography,
  TextField,
  Checkbox,
  useTheme,
  Popover,
  Tooltip,
  Button,
  Box,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useMemo, useRef, useState } from "react";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { enqueueSnackbar, useSnackbar } from "notistack";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { PiMathOperationsBold } from "react-icons/pi";
import { useMutation } from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import TitleIcon from "@mui/icons-material/Title";
import NotesIcon from "@mui/icons-material/Notes";
import { useTranslation } from "react-i18next";
import { CgArrowRightR } from "react-icons/cg";
import AddIcon from "@mui/icons-material/Add";
import IsoIcon from "@mui/icons-material/Iso";
import { GrMultiple } from "react-icons/gr";
import { format } from "date-fns";
import { produce } from "immer";

import {
  setStageLoadingText,
  setRefreshData,
} from "../../../store/slices/appmain";
import CategoryTreeSelection from "../../../components/Overlay/CategoryTreeSelection";
import DescriptionInput from "../../../components/Overlay/DescriptionInput";
import TitleInput from "../../../components/Overlay/TitleInput";
import CustomModal from "../../../components/Model/CustomModal";
import AmountView from "../../../components/Overlay/AmountView";
import useSubscriptions from "../../../hooks/useSubscriptions";
import { setCategories } from "../../../store/slices/category";
import IOSSwitch from "../../../components/Switchs/IOSSwitch";
import DropDown from "../../../components/Overlay/DropDown";
import CategoryChip from "../../../components/CategoryChip";
import { Color, Constant, Fonts } from "../../../Helper";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import { queryClient } from "../../../App";
import APICall from "../../../APICall";
import store from "../../../store";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const RULE_SELECT = [
  { uuid: 1, title: "Categorize" },
  // { uuid: 2, title: "Categorize and Ignore" },
  // { uuid: 3, title: t("Ignore") },
];

const RuleModel = ({
  open,
  setOpen,
  extraPayload = null,
  transactionsRow = null,
  rule = null,
  notEditable = false,
  modalType = "add",
  type = "manual",
  preventClose,
}) => {
  const theme = useTheme();
  let itemId = useRef(null);
  const rules_index = useRef(0);
  const applyRulesArray = useRef([]);
  const isBatchApplyRules = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();

  //redux state
  const auto_categorization_level = useSelector(
    (state) => state.settingsSlice?.profile?.auto_categorization_level
  );
  const state = useSelector((state) => state.globalSlice.state);
  const fields = [
    {
      title: t("rule_field_same_title"),
      field: "title",
      uuid: "title",
    },
    {
      title: t("rule_field_similar_title"),
      field: "similar_title",
      uuid: "similar_title",
    },
    {
      title: t("rule_field_title_contains"),
      field: "title_contains",
      uuid: "title_contains",
    },
    {
      title: t("rule_field_amount"),
      field: "gross_value",
      uuid: "gross_value",
    },
    { title: t("rule_field_same_note"), field: "note", uuid: "note" },
    {
      title: t("rule_field_similar_note"),
      field: "similar_note",
      uuid: "similar_note",
    },
    {
      title: t("rule_field_note_contains"),
      field: "note_contains",
      uuid: "note_contains",
    },
    {
      title: t("rule_field_title_or_note"),
      field: "title_or_note",
      uuid: "title_or_note",
    },
  ];
  const TYPE = [
    {
      uuid: 1,
      title: t("Income"),
    },
    {
      uuid: 2,
      title: t("Expense"),
    },
  ];
  const LEVEL = [
    {
      uuid: 0,
      title: t("All"),
    },
    {
      uuid: 1,
      title: t("datasets"),
    },
    {
      uuid: 2,
      title: t("data_sources"),
    },
  ];
  //state
  const [conditions, setConditions] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [showMultipleRuleSwitch, setShowMultipleRuleSwitch] = useState(false);
  const [dataSetItem, setDataSetItem] = useState({
    title: "",
    note: "",
    transaction_title: "",
    data_sources: [],
    datasets: [],
    transaction_type: 1,
    isAddAll: true,
    rule_select: RULE_SELECT[0],
    level: modalType === "add" ? Number(auto_categorization_level) : 0,
  });
  const [error, setError] = useState({
    data_sources: "",
    data_sets: "",
    title: "",
    category: "",
  });
  const [alignment, setAlignment] = useState("and");

  const isGlobal =
    dataSetItem?.level === 0 ||
    dataSetItem?.level === 2 ||
    (dataSetItem?.level === 1 ? dataSetItem?.datasets?.length > 1 : true);
  const isDataSet =
    dataSetItem?.level === 1 && dataSetItem?.datasets?.length === 1
      ? dataSetItem?.datasets?.[0]
      : false;
  const addRuleMutation = useMutation({
    mutationFn: (obj) => createNewRules(obj),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["rules"],
      }),
  });

  //life cycle method
  useEffect(() => {
    if (open && transactionsRow) {
      let array = [];
      const title = transactionsRow?.title;
      const note = transactionsRow?.note;
      const multipleTitles = transactionsRow?.multipleTitles;
      const multipleNotes = transactionsRow?.multipleNotes;
      let id = 0;
      if (multipleTitles?.length > 1 || multipleNotes?.length > 1) {
        setAlignment(`or`);
        setShowMultipleRuleSwitch(true);
        multipleTitles?.forEach((element) => {
          id++;
          array.push({
            id,
            fieldName: "title",
            field: fields[0],
            value: element,
          });
        });
        multipleNotes?.forEach((element) => {
          id++;
          array.push({
            id,
            fieldName: "note",
            field: fields[4],
            value: element,
          });
        });
      } else {
        setShowMultipleRuleSwitch(false);
        setIsMultiple(false);
      }

      if (title) {
        id++;
        array.push({
          id,
          fieldName: "title",
          field: fields[0],
          value: title,
        });
      }
      if (note) {
        id++;
        array.push({
          id,
          fieldName: "note",
          field: fields[4],
          value: note,
        });
      }
      setDataSetItem((prev) => ({
        ...prev,
        title: `Manual - ${title || "multiple titles or notes"}`,
        note,
        transaction_type: transactionsRow?.transaction_type,
        category: transactionsRow?.category,
      }));
      setConditions(array);
    }
  }, [transactionsRow, open]);

  useEffect(() => {
    if (open && rule) {
      itemId.current = rule?.uuid;

      const filter = rule.filters.filters;
      let array = [];
      setAlignment(rule.filters.operator);
      setDataSetItem({
        ...rule,
        rule_select: RULE_SELECT[0],
      });
      if (filter?.length > 0) {
        filter?.forEach((element, i) => {
          const fieldObj = fields.find((o1) => o1.field === element.field);
          array.push({
            id: i,
            fieldName: element.field,
            value:
              element?.field === "gross_value"
                ? Math.abs(element?.value)
                : element?.value,
            field: fieldObj,
          });
        });
        setConditions(array);
      } else {
        setIsAll(true);
      }
    }
  }, [rule, open]);

  //api
  const applyRules = async (id, obj) => {
    dispatch(setStageLoadingText("process_applying_all_rules_in_progress"));

    await APICall("post", EndPoints.transactions_rules + `${id}/apply/`, obj)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setTimeout(() => {
            getRulesByID(id);
          }, 4000);
        }
      })
      .catch(() => {
        dispatch(setStageLoadingText(false));
      });
  };

  const applyBatchRulesById = async (id, obj) => {
    await APICall("post", EndPoints.transactions_rules + `${id}/apply/`, obj)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setTimeout(() => {
            getRulesByID(id);
          }, 4000);
        }
      })
      .catch(() => {
        dispatch(setStageLoadingText(false));
      });
  };

  const applyBatchRules = async (array) => {
    dispatch(setStageLoadingText("process_applying_all_rules_in_progress"));
    applyRulesArray.current = array;
    rules_index.current = 0;
    isBatchApplyRules.current = true;

    await applyBatchRulesById(
      applyRulesArray.current?.[rules_index?.current]?.uuid,
      {
        override_existing_category: true,
      }
    );
  };

  const getRulesByID = async (id) => {
    await APICall("get", EndPoints.transactions_rules + `${id}/`)
      .then(async (response) => {
        if (response.status === 200 && response?.data) {
       
          if (
            dataSetItem?.last_applying_date ===
            response?.data?.last_applying_date
          ) {
            setTimeout(() => {
              getRulesByID(id);
            }, 4000);
          } else {
            if (isBatchApplyRules.current) {
              rules_index.current = rules_index.current + 1;
              if (rules_index.current < applyRulesArray.current?.length) {
                const isLastRule =
                  rules_index.current === applyRulesArray.current?.length - 1;

                if (isLastRule) {
                  isBatchApplyRules.current = false;
                }
                await applyBatchRulesById(
                  applyRulesArray.current?.[rules_index?.current]?.uuid,
                  {
                    override_existing_category: true,
                  }
                );
              }
            } else if (modalType === "add") {
              enqueueSnackbar(t("New_Rules_Added_Successfully"));
              dispatch(setRefreshData(Date.now()));
              if (preventClose) {
                preventClose.current = false;
              }
              setOpen(false);
              if (extraPayload?.onClickExclude) {
                extraPayload?.onClickExclude();
              } else {
                dispatch(setStageLoadingText(null));
              }
            } else {
              dispatch(setStageLoadingText(null));
              handleClose();
              resetRules();
              if (extraPayload?.onClickKeepRule) {
                setTimeout(() => {
                  extraPayload?.onClickKeepRule(response?.data);
                }, 0);
              }
            }
          }
        }
      })
      .catch(() => {
        dispatch(setStageLoadingText(null));
      });
  };

  const updateRulesByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions_rules + `${id}/`, obj).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          if (dataSetItem?.isAddAll) {
            isBatchApplyRules.current = false;
            await applyRules(itemId.current, {
              override_existing_category: true,
            });
          } else {
            dispatch(setStageLoadingText(null));
            handleClose();
            resetRules();
            if (extraPayload?.onClickKeepRule) {
              setTimeout(() => {
                extraPayload?.onClickKeepRule(response?.data);
              }, 0);
            }
          }
        }
      }
    );
  };

  const createNewRules = async (obj) => {
    await APICall("post", EndPoints.transactions_rules, obj).then(
      async (response) => {
        if (response.status === 201 && response.data) {
          if (dataSetItem?.isAddAll) {
            isBatchApplyRules.current = false;
            await applyRules(response.data?.uuid, {
              override_existing_category: true,
            });
          } else {
            enqueueSnackbar(t("New_Rules_Added_Successfully"));
            if (preventClose) {
              preventClose.current = false;
            }
            setOpen(false);
            if (extraPayload?.onClickExclude) {
              extraPayload?.onClickExclude();
            } else {
              dispatch(setStageLoadingText(null));
            }
          }
        }
      }
    );
  };

  const createBatchNewRules = async (array) => {
    await APICall(
      "post",
      EndPoints.transactions_rules + "batch_add/",
      array
    ).then(async (response) => {
      if (response.status === 201 && response.data) {
        if (dataSetItem?.isAddAll) {
          await applyBatchRules(response.data);
        } else {
          enqueueSnackbar(t("New_Rules_Added_Successfully"));
          if (preventClose) {
            preventClose.current = false;
          }
          setOpen(false);
          if (extraPayload?.onClickExclude) {
            extraPayload?.onClickExclude();
          } else {
            dispatch(setStageLoadingText(null));
          }
        }
      }
    });
  };

  //functions
  const resetRules = () => {
    setTimeout(() => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "rules",
      });
    }, 0);
  };

  const handleEditChange = (e) => {
    let text = e.target.value;
    if (error?.["title"]) setError({ ...error, title: "" });
    setDataSetItem({ ...dataSetItem, title: text });
    if (text !== "") {
    } else {
      if (error?.["title"]) setError({ ...error, title: "title_empty_error" });
    }
  };

  const handleEditDescriptionChange = (e) => {
    let text = e.target.value;
    setDataSetItem({ ...dataSetItem, note: text });
  };

  const onClickCategoryTitle = (e, item) => {
    if (error?.["category"]) setError({ ...error, category: "" });
    setDataSetItem({ ...dataSetItem, category: item?.uuid });
  };

  const onDelete = () => {
    if (error?.["category"]) {
      setError({ ...error, category: "select_category" });
    }
    setDataSetItem({ ...dataSetItem, category: null });
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const onClickAddCondition = () => {
    let array = [...conditions];
    let obj = {
      id: conditions.length,
      field: null,
      value: null,
    };
    array.push(obj);
    setConditions(array);
    if (error?.["filter"]) setError({ ...error, filter: "" });
  };

  const handleClose = async () => {
    if (preventClose) {
      preventClose.current = false;
    }
    setOpen(false);
    setError({});
    setDataSetItem(null);
    setConditions([]);
    setAlignment("and");
  };

  const onSaveRule = async () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (!dataSetItem?.title || dataSetItem?.title?.trim() === "") {
        setError((prev) => ({ ...prev, title: "title_empty_error" }));
        return;
      }
      if (!dataSetItem?.transaction_type) {
        setError((prev) => ({
          ...prev,
          transaction_type: "transaction_type_empty_error",
        }));
        return;
      }

      if (dataSetItem?.level === 1 && dataSetItem?.datasets?.length === 0) {
        setError((prev) => ({
          ...prev,
          datasets: "dataset_empty_error",
        }));
        return;
      }
      if (dataSetItem?.level === 2 && dataSetItem?.data_sources?.length === 0) {
        setError((prev) => ({
          ...prev,
          data_sources: "ds_empty_error",
        }));
        return;
      }
      let filter = [];
      if (!isAll) {
        let errorText = "";
        if (conditions?.length > 0) {
          let alreadyAddedCondition = [];
          conditions?.forEach((element) => {
            if (
              element?.value?.toString()?.trim("") &&
              (alignment === "and"
                ? !alreadyAddedCondition?.includes(element?.fieldName)
                : true)
            ) {
              alreadyAddedCondition?.push(element?.fieldName);
              const filterObj = {
                field: element?.fieldName,
                value:
                  element?.fieldName === "gross_value"
                    ? dataSetItem?.transaction_type === 1
                      ? Math.abs(element?.value || 0)
                      : "-" + Math.abs(element?.value || 0)
                    : element?.value || "",
              };
              filter.push(filterObj);
            }
          });
        }
        if (filter?.length === 0) {
          errorText = "Add_one_Condition";
        }
        if (errorText) {
          setError((prev) => ({ ...prev, filter: errorText }));
          return;
        }
      }

      if (!dataSetItem?.category) {
        setError((prev) => ({ ...prev, category: "select_category" }));
        return;
      }
      let obj = {
        ...dataSetItem,
        transaction_title: "",
        state: 1,
        type,
        filters: {
          operator: alignment,
        },
      };
      if (dataSetItem?.level === 0) {
        obj.datasets = [];
        obj.data_sources = [];
      }
      if (dataSetItem?.level === 1) {
        obj.data_sources = [];
      }
      if (dataSetItem?.level === 2) {
        obj.datasets = [];
      }
      if (!isAll) {
        const isTitleAdded = filter?.filter((item) => item?.field === "title");

        if (isTitleAdded?.length === 1) {
          obj.transaction_title = isTitleAdded?.[0]?.value;
        }
      }

      if (modalType !== "edit" && !isAll && isMultiple) {
        let array = [];
        filter?.forEach((item) => {
          let element = {
            ...obj,
            title: `Manual - ${item?.value}`,
            filters: {
              operator: "and",
              filters: [item],
            },
          };

          if (item?.field === "title") {
            element.transaction_title = item?.value;
          }
          array.push(element);
        });

        await createBatchNewRules(array);
      } else {
        obj.filters.filters = filter;
        if (modalType === "edit") {
          await updateRulesByID(itemId.current, obj);
        } else {
          addRuleMutation.mutate(obj);
        }
      }
    }
  };

  const onChangeFields = (e, obj, index) => {
    let array = [...conditions];
    array[index] = { ...array[index], field: obj, fieldName: obj?.field };
    setConditions(array);
  };

  const onChangeDate = (e, index) => {
    let array = [...conditions];
    const value =
      e.target.value && e.target.value !== "Invalid Date"
        ? format(new Date(e.target.value), "yyyy-MM-dd")
        : null;

    array[index] = {
      ...array[index],
      value: value,
    };
    setConditions(array);
  };

  const onChangeAmount = (originalValue, name, type, index) => {
    let array = [...conditions];

    array[index] = {
      ...array[index],
      value: originalValue?.replace("-", ""),
    };
    setConditions(array);
  };

  const onChangeNote = (e, index, field) => {
    let array = [...conditions];
    const title = array?.find((o1) => o1.fieldName === "title")?.value || "";
    let value = e.target.value;

    array[index] = {
      ...array[index],
      value: value,
    };

    setConditions(array);
    if (error?.["title"]) setError({ ...error, title: "" });

    if (
      field === "note" ||
      field === "similar_note" ||
      field === "note_contains"
    ) {
      setDataSetItem({ ...dataSetItem, title: `${title} - ${value}` });
    }
  };

  const onClickConditionDelete = (index) => {
    let array = conditions?.filter((o1) => o1.id !== index);
    setConditions(array);
  };

  const onChangeSelect = (e, value) => {
    let data = state.find((o1) => o1.title === "Ignored");
    setDataSetItem({
      ...dataSetItem,
      rule_select: value ?? null,
      target_state: value.uuid === 1 ? null : data.uuid,
      category: value.uuid === 3 ? null : dataSetItem.category,
    });
  };

  const handleChangeCheck = (e) => {
    setDataSetItem({
      ...dataSetItem,
      isAddAll: e.target.checked,
    });
  };

  const onClickSwitch = (e) => {
    setIsAll(e.target.checked);
  };

  const onClickRuleSwitch = (e) => {
    setIsMultiple(e.target.checked);
  };

  const onChangeTransactionType = (e, value) => {
    const obj = {
      transaction_type: value?.uuid,
      category: null,
    };
    setDataSetItem((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onChangeLevel = (e, value) => {
    const obj = {
      level: value?.uuid,
      category: null,
    };
    setDataSetItem((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  return (
    <CustomModal
      open={open}
      modalType={modalType}
      onClose={handleClose}
      onAdd={onSaveRule}
      heading={modalType === "edit" ? t(`edit_rule`) : t(`add_rule`)}
      textAdd={
        modalType === "edit"
          ? dataSetItem?.isAddAll
            ? t("edit_rule_and_apply")
            : t(`Update`)
          : dataSetItem?.isAddAll
            ? t("add_rule_and_apply")
            : t(`Add`)
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: Color.white,
          width: "46rem",
          height: "fit-content",
          p: "1.5rem",
          pr: "3rem",
        }}
      >
        <div style={{ display: "flex", marginTop: "0.5rem", width: "100%" }}>
          <TitleIcon
            sx={{
              color: theme.palette.color.blueGrey[700],
              ml: "0.2rem",
              mr: "0.75rem",
              mt: dataSetItem?.title ? "0.25rem" : "0.75rem",
              fontSize: "1.4rem",
            }}
          />
          <TitleInput
            value={dataSetItem?.title || ""}
            onChange={handleEditChange}
            helperText={t(error?.["title"])}
            error={Boolean(error?.["title"])}
            hideTitle
            label={t("rule_form_title")}
            likeGoogle
            variant="filled"
          />
        </div>
        <div style={{ display: "flex", marginTop: "0.5rem", width: "100%" }}>
          <NotesIcon
            sx={{
              color: theme.palette.color.blueGrey[700],
              ml: "0.2rem",
              mr: "0.75rem",
              mt: dataSetItem?.note ? "0.25rem" : "0.75rem",
              fontSize: "1.4rem",
            }}
          />
          <DescriptionInput
            value={dataSetItem?.note || ""}
            onChange={handleEditDescriptionChange}
            hideTitle
            rows={null}
            label={t("rule_form_note")}
            likeGoogle
            variant="filled"
            sx={{ width: "100%" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "1.5rem",
            width: "100%",
          }}
        >
          <IsoIcon
            sx={{
              color: theme.palette.color.blueGrey[700],
              ml: "0.2rem",
              mt: "0.5rem",
              mr: "0.8rem",
              fontSize: "1.4rem",
            }}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "90%",
            }}
          >
            <DropDown
              sx={{ width: "48%" }}
              disablePortal
              disableClearable
              hideTitle
              fontSize="0.9rem"
              name="transaction_type"
              disabled={notEditable}
              options={TYPE}
              value={TYPE?.find(
                (o1) => o1?.uuid === dataSetItem?.transaction_type
              )}
              onChange={onChangeTransactionType}
              getOptionLabel={(option) => t(option?.title) ?? ""}
              renderOption={(option) => t(option?.title) ?? ""}
              error={error?.["transaction_type"]}
              label={t("rule_form_type")}
              tooltip={t("rule_form_type")}
              likeGoogle
              variant="filled"
              mb="0"
            />
            <DropDown
              sx={{ width: "48%" }}
              disablePortal
              disableClearable
              hideTitle
              fontSize="0.9rem"
              name="level"
              options={LEVEL}
              value={LEVEL?.find((o1) => o1?.uuid === dataSetItem?.level)}
              onChange={onChangeLevel}
              getOptionLabel={(option) => t(option?.title) ?? ""}
              renderOption={(option) => t(option?.title) ?? ""}
              error={error?.["level"]}
              label={t("Level")}
              tooltip={t("Level")}
              likeGoogle
              variant="filled"
              mb="0"
            />
          </div>
        </div>

        {/* org and DS */}
        {dataSetItem?.level === 0 ? null : (
          <div
            style={{
              display: "flex",
              marginTop: "2.5rem",
              width: "100%",
            }}
          >
            <DataUsageIcon
              sx={{
                color: theme.palette.color.blueGrey[700],
                ml: "0.2rem",
                mt: "0.25rem",
                mr: "1.65rem",
                fontSize: "1.4rem",
              }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              {dataSetItem?.level === 2 ? null : (
                <PopOverView
                  width="48%"
                  title="datasets"
                  type="datasets"
                  error={error?.["datasets"]}
                  setError={setError}
                  selectedIds={dataSetItem?.datasets || []}
                  setDataSetItem={setDataSetItem}
                />
              )}
              {dataSetItem?.level === 1 ? null : (
                <PopOverView
                  width="48%"
                  title="data_sources"
                  type="data_sources"
                  error={error?.["data_sources"]}
                  setError={setError}
                  selectedIds={dataSetItem?.data_sources || []}
                  setDataSetItem={setDataSetItem}
                />
              )}
            </div>
          </div>
        )}

        {/* condition */}
        <div
          style={{
            display: "flex",
            marginTop: "2.5rem",
            width: "100%",
          }}
        >
          <PiMathOperationsBold
            style={{
              color: theme.palette.color.blueGrey[700],
              marginLeft: "0.2rem",
              marginRight: "1.65rem",
              marginTop: "0.25rem",
              fontSize: "1.4rem",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Typography
              color="color.slate.600"
              variant="caption"
              fontWeight={"fontWeightMedium"}
              sx={{ mr: "0.5rem" }}
            >
              {t("Filter")}
            </Typography>
            <IOSSwitch onClick={onClickSwitch} checked={isAll} />
            <Typography
              color="color.slate.600"
              variant="caption"
              fontWeight={"fontWeightMedium"}
              sx={{ ml: "0.5rem" }}
            >
              {t("Apply to all")}
            </Typography>
          </div>
        </div>

        {/* filters */}
        {isAll ? null : (
          <div
            style={{
              marginTop: "1.5rem",
              marginLeft: "9%",
              width: "91%",
              backgroundColor: theme.palette.color.slate[50],
              borderRadius: theme.borderRadius.main,
              padding: "1rem",
            }}
          >
            <Box sx={{ fontSize: "0.85rem", fontWeight: 600 }}>
              {t("Select_Operator")}: {"  "}
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                size="small"
                sx={{
                  height: "2rem",
                  "& .MuiToggleButtonGroup-grouped.Mui-selected ": {
                    backgroundColor: theme.palette.primary[50],
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                  },
                }}
              >
                <ToggleButton value="and" sx={{ width: "3.75rem" }}>
                  {t("And")}
                </ToggleButton>
                <ToggleButton value="or" sx={{ width: 60 }}>
                  {t("Or")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {error?.["filter"] && (
              <Typography sx={{ color: Color.red, fontSize: "0.8rem" }}>
                {t(error?.["filter"])}
              </Typography>
            )}
            {conditions?.map((item, index) => {
              return (
                <Box
                  key={`conditions-${item?.fieldName}-${index}`}
                  sx={{
                    width: "100%",
                    position: "relative",
                    my: "0.5rem",
                    height: "3rem",
                    borderRadius: theme.borderRadius.main,
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: Color.white,
                    "& .MuiInputBase-root": {
                      mt: "1rem",
                      pr: "1rem !important",
                      "&.Mui-disabled": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& .MuiAutocomplete-endAdornment": {
                      top: "15%",
                    },
                    "&:hover": {
                      "& span": {
                        display: "block",
                      },
                    },
                  }}
                >
                  <DropDown
                    mb={0}
                    hideTitle
                    label={t("field")}
                    variant="standard"
                    id="blur-on-select"
                    blurOnSelect
                    tooltip={item?.field?.title}
                    value={item?.field ?? null}
                    onChange={(e, value) => onChangeFields(e, value, index)}
                    options={fields}
                    sx={{
                      width: "46%",
                      "& .MuiFormLabel-root": {
                        transform: "translate(1px, 1px) scale(1) !important",
                      },
                      "&input": {
                        fontSize: "0.85rem",
                      },
                    }}
                  />
                  {item?.field && (
                    <Box
                      sx={{
                        ml: "1rem",
                        display: "flex",
                        alignItems: "end",
                        height: "3rem",
                      }}
                    >
                      {[
                        "title",
                        "similar_title",
                        "title_contains",
                        "title_or_note",
                      ].includes(item?.field?.field) && (
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={item?.value ?? null}
                          onChange={(e) => onChangeNote(e, index)}
                          sx={{
                            width: "15rem",
                            ml: "0.5rem",
                            "& input": {
                              fontSize: "0.85rem",
                            },
                          }}
                        />
                      )}
                      {["note", "similar_note", "note_contains"].includes(
                        item?.field?.field
                      ) && (
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={item?.value ?? null}
                          onChange={(e) => onChangeNote(e, index)}
                          sx={{
                            width: "15rem",
                            ml: "0.5rem",
                            "& input": {
                              fontSize: "0.85rem",
                            },
                          }}
                        />
                      )}
                      {item?.field?.field === "gross_value" && (
                        <AmountView
                          width={"15rem"}
                          height="auto"
                          sx={{ ml: "0.5rem" }}
                          fontSize="0.85rem"
                          defaultValue={Math.abs(item?.value)}
                          name="value"
                          onBlur={(originalValue, name, type) =>
                            onChangeAmount(originalValue, name, type, index)
                          }
                          variant="standard"
                          allowNegativeValue={false}
                          mb={0}
                        />
                      )}

                      {item?.field?.field === "date" && (
                        <TextField
                          id="date"
                          type="date"
                          size="small"
                          value={item?.value}
                          onChange={(e) => onChangeDate(e, index)}
                          mindate={new Date().setMonth(0)}
                          maxdate={new Date().setMonth(11)}
                          sx={{ width: "15rem", ml: "0.5rem" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </Box>
                  )}

                  <Box component="span" sx={{ display: "none" }}>
                    <DeleteIcon
                      onClick={() => onClickConditionDelete(item?.id)}
                      sx={{
                        position: "absolute",
                        right: 10,
                        top: 15,
                        color: Color.themeColor,
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
            <Button
              startIcon={<AddIcon />}
              sx={{ textTransform: "capitalize", mt: "0.5rem", ml: "-0.4rem" }}
              onClick={onClickAddCondition}
              // disabled={conditions?.length > 2}
            >
              {t("Add_Condition")}
            </Button>
          </div>
        )}

        {/* switch to single or multiple */}
        {showMultipleRuleSwitch && !isAll && modalType !== "edit" ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "2.5rem",
            }}
          >
            <GrMultiple
              style={{
                color: theme.palette.color.blueGrey[700],
                marginLeft: "0.2rem",
                marginRight: "0.8rem",
                marginTop: "0.5rem",
                fontSize: "1.3rem",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "90%",
              }}
            >
              <Typography
                color="color.slate.600"
                variant="caption"
                fontWeight={"fontWeightMedium"}
                sx={{
                  mr: "0.5rem",
                  width: "11rem",
                  textAlign: "left",
                  lineHeight: "0.9rem",
                  ml: "1rem",
                }}
              >
                <Translate i18nkey={"rule_overlay_create_one_rule"} />
              </Typography>
              <IOSSwitch onClick={onClickRuleSwitch} checked={isMultiple} />
              <Typography
                color="color.slate.600"
                variant="caption"
                fontWeight={"fontWeightMedium"}
                sx={{
                  ml: "0.5rem",
                  width: "11rem",
                  lineHeight: "0.9rem",
                }}
              >
                <Translate i18nkey={"rule_overlay_create_individual_rule"} />
              </Typography>
            </div>
          </div>
        ) : null}

        {/* Action */}
        <div
          style={{
            display: "flex",
            marginBlock: "2.5rem",
            width: "100%",
          }}
        >
          <CgArrowRightR
            style={{
              color: theme.palette.color.blueGrey[700],
              marginLeft: "0.2rem",
              marginRight: "0.8rem",
              marginTop: "0.5rem",
              fontSize: "1.4rem",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              gap: "1rem",
              position: "relative",
            }}
          >
            <DropDown
              mb={0}
              disableClearable
              options={RULE_SELECT}
              value={dataSetItem?.rule_select}
              onChange={onChangeSelect}
              renderOption={(option) => t(option?.title) ?? ""}
              getOptionLabel={(option) => t(option?.title) ?? ""}
              hideTitle
              likeGoogle
              variant="filled"
              label={t("rule_form_select")}
              sx={{
                width: "30%",
                "& .MuiAutocomplete-root": {
                  height: "fit-content",
                },
              }}
            />

            <CategoryTreeSelection
              isDataSet={isDataSet}
              isGlobal={isGlobal}
              disabled={!dataSetItem?.transaction_type}
              onClickCategoryTitle={onClickCategoryTitle}
              categoryID={dataSetItem?.category ?? null}
              onDelete={onDelete}
              type={dataSetItem?.transaction_type}
              width="13rem"
              title="Select Category"
            />
            <Typography
              sx={{
                color: error?.["category"]
                  ? theme.palette.color.red[500]
                  : theme.palette.color.slate[700],
                fontSize: "0.8rem",
                pt: "0.25rem",
                display: "inline-flex ",
                alignItems: "center",
                position: "absolute",
                left: "33%",
                top: "94%",
              }}
            >
              {error?.["category"]
                ? t(error?.["category"])
                : isGlobal
                  ? t("Global categories")
                  : t("Organization categories")}
            </Typography>
            <span>
              <Typography
                color="color.mainTitle"
                variant="subtitle2"
                fontWeight={"fontWeightMediumBold"}
                sx={{ ml: "0.5rem", mb: "0.25rem" }}
              >
                {t("rule_overlay_apply_to")}
              </Typography>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                }}
              >
                <Tooltip
                  arrow
                  placement="top"
                  followCursor
                  title={t("rule_overlay_apply_to_new_tooltip")}
                >
                  <Typography
                    color="color.slate.600"
                    variant="caption"
                    fontWeight={"fontWeightMedium"}
                    sx={{ ml: "0.5rem" }}
                  >
                    {t("New")}
                  </Typography>
                </Tooltip>

                <IOSSwitch
                  onClick={handleChangeCheck}
                  checked={dataSetItem?.isAddAll}
                />
                <Tooltip
                  arrow
                  placement="top"
                  followCursor
                  title={t("rule_overlay_apply_to_all_tooltip")}
                >
                  <Typography
                    color="color.slate.600"
                    variant="caption"
                    fontWeight={"fontWeightMedium"}
                    sx={{ ml: "0.5rem" }}
                  >
                    {t("All")}
                  </Typography>
                </Tooltip>
              </span>
            </span>
          </div>
        </div>

        {transactionsRow?.bank_category_id ? (
          <SuggestionCategory
            bank_category_id={transactionsRow?.bank_category_id}
            setDataSetItem={setDataSetItem}
          />
        ) : null}
      </Box>
    </CustomModal>
  );
};

export default RuleModel;

const PopOverView = ({
  width,
  type,
  title,
  error,
  setError,
  setDataSetItem,
  selectedIds,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  //redux
  const dataSourceById = useSelector(
    (state) => state.globalSlice?.dataSourceById
  );
  const accountByDS = useSelector((state) => state.globalSlice?.accountByDS);
  const dataSource = useSelector((state) => state.globalSlice?.dataSource);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);

  //state
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const value = useMemo(() => {
    let _value = "";
    if (type === "data_sources") {
      selectedIds?.forEach((item, index) => {
        const _account = accountByDS?.[item]?.[0];
        const _ds = dataSourceById?.[item]?.[0];
        _value =
          _value +
          `${index !== 0 ? " , " : ""}` +
          `${_ds?.title || _account?.bank_details?.title || _account?.name}`;
      });
    } else {
      const _data = dataSetList?.filter((item) =>
        selectedIds?.includes(item?.uuid)
      );
      _data?.forEach((item, index) => {
        _value = _value + `${index !== 0 ? " , " : ""}` + `${item?.title}`;
      });
    }
    return _value;
  }, [accountByDS, dataSetList, dataSourceById, selectedIds, type]);

  const data = useMemo(() => {
    let _data = [];
    if (type === "data_sources") {
      _data = dataSource.filter((o1) => o1.type !== 1);
    } else {
      _data = dataSetList;
    }
    return _data;
  }, [dataSetList, dataSource, type]);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (event) => {
    setAnchorEl(null);
  };

  const onChangeSelections = (option) => {
    if (option === "all") {
      if (selectedIds?.length === data?.length) {
        setDataSetItem((prev) => ({
          ...prev,
          category: null,
          [type]: [],
        }));
      } else {
        setDataSetItem((prev) => ({
          ...prev,
          category: null,
          [type]: data.map((o1) => o1.uuid),
        }));
      }
    } else {
      const updatedData = produce(selectedIds, (draftState) => {
        if (draftState?.includes(option?.uuid)) {
          const index = draftState.findIndex((o1) => o1 === option?.uuid);
          if (index > -1) {
            draftState.splice(index, 1);
          }
        } else {
          draftState.push(option?.uuid);
        }
      });
      setDataSetItem((prev) => ({
        ...prev,
        category: null,
        [type]: updatedData,
      }));
    }

    setError((prev) => ({
      ...prev,
      level: null,
      transaction_type: null,
      data_sources: null,
      datasets: null,
    }));
  };

  return (
    <Box
      sx={{
        width: width || "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          width: "100%",
          textAlign: "start",
          color: theme.palette.color.mainTitle,
          fontWeight: 600,
          fontSize: "0.85rem",
          mb: "0.25rem",
        }}
      >
        {t(title)}
      </Typography>
      <OutlinedInput
        onClick={onOpen}
        size="small"
        type="button"
        error={Boolean(error?.[type])}
        sx={{
          width: "100%",
          cursor: "pointer",
          borderRadius: theme.borderRadius.main,
          "& input": {
            color: theme.palette.color.slate[500],
            fontWeight: 500,
            fontSize: "0.85rem",
            lineHeight: "0.85rem",
            cursor: "pointer",
            textAlign: "left",
          },
        }}
        value={t(value)}
        endAdornment={<ArrowDropDownIcon sx={{ color: Color.blueGrey500 }} />}
      />
      <Typography
        sx={{
          width: "100%",
          textAlign: "start",
          fontFamily: Fonts.Text,
          color: Color.error,
          fontSize: "0.7rem",
          position: "absolute",
          bottom: "-1.5rem",
        }}
      >
        {t(error)}
      </Typography>
      {open ? (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: theme.borderRadius.main,
                boxShadow: Constant.OverlayBoxShadow,
              },
            },
          }}
          sx={{
            zIndex: 1303,
            mt: "0.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              minHeight: "3.125rem",
              p: "1rem",
              width: "fit-content",
              minWidth: "22.5rem",
              position: "relative",
            }}
          >
            <Box
              key={"all"}
              onClick={() => onChangeSelections("all")}
              style={{ cursor: "pointer" }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: "0.5rem" }}
                checked={selectedIds?.length === data?.length}
              />
              {t("All")}
            </Box>
            {data?.map((item) => {
              let title = "";
              if (type === "data_sources") {
                const _account = accountByDS?.[item?.uuid]?.[0];
                title = `${
                  item?.title || _account?.bank_details?.title || _account?.name
                } ${item?.alias ? `(${item?.alias})` : ""} - ${
                  item?.type === 10 || item?.type === 12
                    ? "Bank Account"
                    : "Integrations"
                }`;
              } else {
                title = item?.title;
              }
              return (
                <Box
                  key={item?.uuid}
                  onClick={() => onChangeSelections(item)}
                  style={{ cursor: "pointer" }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: "0.5rem" }}
                    checked={selectedIds?.includes(item?.uuid)}
                  />
                  {title}
                </Box>
              );
            })}
          </Box>
        </Popover>
      ) : null}
    </Box>
  );
};

const SuggestionCategory = ({ bank_category_id, setDataSetItem }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemRow = useRef();

  //api
  const getCategories = async () => {
    // let endUrl = "";
    // if (!use_global_categories) {
    //   endUrl = `?dataset=${dataset}`;
    // } else {
    //   endUrl = `?is_global=true`;
    // }
    await APICall("get", EndPoints.category).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setCategories({ data: response.data.results, type: "all" }));
      }
    });
  };

  const addCategoryApi = async (obj) => {
    await APICall("post", EndPoints.category, obj, {
      doNotCatchRespond: true,
    }).then(async (response) => {
      if (response.status === 201 && response.data) {
        await getCategories();
        dispatch(setStageLoadingText(null));
        setTimeout(() => {
          setDataSetItem((prev) => ({
            ...prev,
            category: response.data?.uuid,
          }));
        }, 0);
      }
      if (response.status === 400 && response.data) {
        if (response?.data?.title?.[0]) {
          enqueueSnackbar(t(response?.data?.title?.[0]), {
            variant: "error",
            autoHideDuration: 4000,
          });
        }
      }
    });
  };

  //functions
  const onClickSuggestionCategory = async (e) => {
    e.stopPropagation();
    const selectionCategoriesByTitle =
      store.getState().categorySlice?.selectionCategoriesByTitle;
    const selectionBankCategoriesByID =
      store.getState().categorySlice?.selectionBankCategoriesByID;
    const bank_category = selectionBankCategoriesByID?.[bank_category_id]?.[0];

    const isCategoryFound =
      selectionCategoriesByTitle?.[bank_category?.title]?.[0];
    if (isCategoryFound) {
      setDataSetItem((prev) => ({ ...prev, category: isCategoryFound?.uuid }));
    } else {
      dispatch(setStageLoadingText("process_category_is_being_created"));
      await addCategoryApi({
        parent: null,
        title: bank_category?.title,
        payment_term_value: bank_category?.payment_term_value,
        type: itemRow.current?.key?.transaction_type === "inflow" ? 1 : 2,
      });
    }
  };
  return (
    <div
      style={{
        marginLeft: "15rem",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        fontSize: "0.8rem",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      {`${t("Suggestions")}: `}
      <CategoryChip
        onClick={onClickSuggestionCategory}
        count="14"
        height="1.8rem"
        fontSize="0.7rem"
        categoryId={bank_category_id}
        backgroundShade={100}
        borderShade={400}
        borderWidth={3}
        borderType="dashed"
        isBankCategory
        isSuggestion
        blackTitle
        showTooltip
        postView={
          <p
            style={{
              backgroundColor: theme.palette?.color?.slate[200],
              borderRadius: theme.borderRadius.main,
              paddingInline: "0.5rem",
              paddingBlock: "0.25rem",
            }}
          >
            {" "}
            {`${t("Add")}`}
          </p>
        }
        style={{
          display: "flex",
        }}
      />
    </div>
  );
};
