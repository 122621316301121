import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import React, { useRef, useState, useMemo } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import enUS from "date-fns/locale/en-US";
import { useSnackbar } from "notistack";
import { v4 as v4uuid } from "uuid";
import de from "date-fns/locale/de";
import { format } from "date-fns";
import _ from "underscore";

import {
  getAllTransactionsByParams,
  deleteBatchTransactions,
} from "../../Helper/data";
import { setRecurring_rules } from "../../store/slices/global";
import { setPopupStatus3 } from "../../store/slices/datasets";
import useSubscriptions from "../../hooks/useSubscriptions";
import { setLoading } from "../../store/slices/appmain";
import ActionViewWrapper from "../ActionViewWrapper.js";
import TailwindButton from "../Overlay/TailwindButton";
import ReconcileOverlay from "./ReconcileOverlay";
import Translate from "../../hooks/HOC/Translate";
import EndPoints from "../../APICall/EndPoints";
import initialData from "./../../Helper/data";
import ActionButton from "../ActionButton";
import MenuView from "../Overlay/MenuView";
import SplitModal from "./SplitModal";
import { Color } from "../../Helper";
import APICall from "../../APICall";
import store from "../../store";

const menuOptions = [
  {
    value: 1,
    label: "transaction-edit-form-past-button",
    tooltip: "edit_form_update_past_button_tooltip",
  },
];
const ActionButtonView = ({
  cardItem,
  tab,
  disabled,
  isTransactionFormUpdated,
  recurring_Obj,
  onCloseTransactionEdit,
  onSaveRecurrence,
  onClose,
  onCancel,
  addBatch,
  updateFlag,
  deleteCardByIdApi,
  internalDsRef,
  showSaved,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();

  let splitModalType = useRef("add");
  let deleteType = useRef("single");
  let localData = useRef({ response: [], payload: [] });

  //redux state
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const modalType = transactionsOverlayStatus?.payload?.modalType;
  const transaction_type = transactionsOverlayStatus?.payload?.transaction_type;

  //state
  const [open, setOpen] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [isSplitOpen, setIsSplitOpen] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const isFirstRecurrence = useMemo(() => {
    if (recurring_Obj?.start_date && cardItem?.due_date && cardItem?.uuid) {
      return (
        format(new Date(recurring_Obj?.start_date), "yyyy-MM") ===
        format(new Date(cardItem?.due_date), "yyyy-MM")
      );
    } else {
      return false;
    }
  }, [cardItem?.uuid, cardItem?.due_date, recurring_Obj]);

  const isReconcileAllow =
    ((cardItem?.recurring_rule && isFirstRecurrence) ||
      !cardItem?.recurring_rule) &&
    (cardItem?.state?.includes("Invoice") ||
      cardItem?.state === "Open" ||
      cardItem?.state === "Booked");

  const keyEnter = React.useCallback(
    (event) => {
      if (modalType !== "edit" && !loadingAdd && event.key === "Enter") {
        event.preventDefault();
        onAdd(event);
      }
      if (modalType === "edit" && showSaved && event.key === "Enter") {
        event.preventDefault();
        onCloseTransactionEdit(event);
      }
      if (event.key === "Escape") {
        event.preventDefault();
        onClose();
      }
    },
    [loadingAdd, modalType, showSaved]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", keyEnter);

    return () => {
      document.removeEventListener("keydown", keyEnter);
    };
  }, [modalType, keyEnter]);

  //api
  const addCard = async (obj, type = "Add") => {
    await APICall("post", EndPoints.transactions, obj)
      .then((response) => {
        if (response.status === 201 && response.data) {
          if (type === "clone") {
            enqueueSnackbar(t("Card Cloned Successfully"));
          } else {
            enqueueSnackbar(t("Card Added Successfully"));
          }
          onClose(true);
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const deleteBatch = async (data) => {
    let uuids = data?.map((o1) => o1.uuid);
    dispatch(setLoading(true));
    const isDeleted = await deleteBatchTransactions(uuids);
    if (isDeleted) {
      dispatch(setLoading(false));
      enqueueSnackbar(t("Cards Deleted Successfully"));
      onClose(true);
    }
  };

  const batchAddValueSets = async (recurring_uuid, value_sets) => {
    let newValueSet = value_sets;
    await APICall(
      "post",
      EndPoints.recurring_rules + `${recurring_uuid}/value_sets/batch_add/`,
      value_sets
    ).then(async (response) => {
      if (response.status === 201 && response.data) {
        newValueSet = response.data;
        const groupData = _.groupBy(response.data, "title");
        for (const o1 of value_sets) {
          if (o1?.items?.length > 0) {
            const uuid = groupData?.[o1?.title]?.[0]?.uuid;
            const valueItem = await batchAddValueSetsItems(
              recurring_uuid,
              uuid,
              o1.items
            );
            if (valueItem?.length > 0) {
              const index = newValueSet?.findIndex((o1) => o1.uuid === uuid);
              if (index > -1) {
                newValueSet[index].items = valueItem;
              }
            }
          }
        }
      }
    });
    return newValueSet;
  };

  const batchAddValueSetsItems = async (
    recurring_uuid,
    value_set_uuid,
    items
  ) => {
    let data = null;
    await APICall(
      "post",
      EndPoints.recurring_rules +
        `${recurring_uuid}/value_sets/${value_set_uuid}/items/batch_add/`,
      items
    ).then((response) => {
      if (response.status === 201 && response.data) {
        data = response.data;
      }
    });

    return data;
  };

  const addRecurringRules = async (obj) => {
    await APICall("post", EndPoints.recurring_rules, obj).then(
      async (response) => {
        if (response.status === 201 && response.data) {
          const recurring_rules = store.getState().globalSlice?.recurring_rules;
          let data = [...recurring_rules];
          if (recurring_Obj?.recurring_type === "employee") {
            const newValueSet = await batchAddValueSets(
              response.data.uuid,
              obj?.value_sets
            );

            if (newValueSet?.length > 0 && response?.data) {
              data.push({
                ...response?.data,
                value_sets: newValueSet,
              });
              dispatch(setRecurring_rules(data));
            }
          } else {
            data.push(response?.data);
            dispatch(setRecurring_rules(data));
          }
        }
      }
    );
  };

  //functions
  const add_transaction = () => {
    let dueDate = null;
    let invoiceDate = null;
    if (
      transaction_type === "expense" &&
      tab === "single" &&
      (!cardItem?.gross_value ||
        Number(cardItem?.gross_value) === -0 ||
        Number(cardItem?.gross_value) === 0)
    ) {
      enqueueSnackbar(t("please add an amount"), {
        variant: "warning",
        autoHideDuration: 5000,
      });
      return;
    }
    setLoadingAdd(true);
    if (cardItem?.due_date && cardItem?.due_date !== "Invalid date") {
      dueDate = format(new Date(cardItem?.due_date), "yyyy-MM-dd");
    }
    if (cardItem?.invoice_date && cardItem?.invoice_date !== "Invalid date") {
      invoiceDate = format(new Date(cardItem?.invoice_date), "yyyy-MM-dd");
    }
    let title = "";
    if (cardItem?.title) {
      title = cardItem?.title;
    } else {
      title = `${t(cardItem?.state)}`;
      if (cardItem?.recurring_rule) {
        title = title + ` Sequence`;
      } else {
        title = title + ` Transaction`;
      }
      if (cardItem?.category) {
        const category = selectionCategoriesByID?.[cardItem?.category]?.[0];
        title =
          title +
          ` For ${category?.immutable ? t(category?.title) : category?.title}`;
      }
      title =
        title +
        ` in ${format(new Date(), "MMMM", {
          locale: locale === "de_DE" ? de : enUS,
        })}`;
    }
    let obj = {
      title: title,
      currency: dataSetData?.currency,
      state: cardItem.state || "Open",
      scenario: cardItem.scenario || "Base",
      note: cardItem.note || "",
      due_date: dueDate || format(new Date(), "yyyy-MM-dd"),
      invoice_date: invoiceDate || null,
      gross_value: localData.current?.gross_value
        ? localData.current?.gross_value
        : cardItem?.gross_value,
      tax: cardItem.tax || null,
      category: cardItem.category || null,
      source: cardItem.source || 1,
      cost_unit: cardItem.cost_unit || null,
      data_source: cardItem.data_source,
      transaction_system: cardItem.transaction_system,
      recurring_rule: cardItem.recurring_rule || null,
      position: 3001,
    };
    internalDsRef.current?.enableInternalDS();
    addCard(obj);
  };

  const onClickCardClone = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      let obj = {
        title: `Copy of ${cardItem?.title}`,
        dataset: cardItem?.dataset,
        state: initialData?.bookedState.includes(cardItem?.state)
          ? "Open"
          : cardItem?.state,
        scenario: cardItem.scenario || "Base",
        note: cardItem?.note,
        due_date: cardItem?.due_date,
        invoice_date: cardItem?.invoice_date,
        tax: cardItem?.tax,
        category: cardItem?.category,
        gross_value: cardItem?.gross_value,
        data_source: dataSetData?.internal_data_source,
        source: 1,
        // data_source: cardItem?.data_source,
        // source: cardItem?.source || 1,
        // transaction_system: cardItem?.transaction_system,
        cost_unit: cardItem?.cost_unit,
        position: cardItem?.position,
        recurring_rule: cardItem?.recurring_rule,
        // transaction_system:transactionSystemByName?.[cardItem?.transaction_system]?.[0]?.uuid,
      };
      internalDsRef.current?.enableInternalDS();
      addCard(obj, "clone");
    }
  };

  const onClickCardDelete = () => {
    deleteType.current = "single";
    dispatch(
      setPopupStatus3({
        id: "simple-popper",
        open: true,
        overlay_type: "delete",
        onConfirm: onOkDelete,
        onClose: onCloseDelete,
        payload: {
          title: t("Delete"),
          message: t("Are you sure, you want to delete this transaction?"),
          type: deleteType.current,
        },
      })
    );
  };

  const onDeleteRecurrence = () => {
    deleteType.current = "recurring";
    dispatch(
      setPopupStatus3({
        id: "simple-popper",
        open: true,
        overlay_type: "delete",
        onConfirm: onOkDelete,
        onClose: onCloseDelete,
        payload: {
          title: t("Delete"),
          message: <Translate i18nkey={`delete_recurring_transaction_warning`} />,
          type: deleteType.current,
        },
      })
    );
  };

  const onClickRecurClone = async () => {
    if (isSubscriptionValid({ showMessage: true })) {
      dispatch(setLoading(true));
      let recurring_rule = v4uuid();
      let recurObj = {
        ...recurring_Obj,
        uuid: recurring_rule,
        period: -2,
      };
      await addRecurringRules(recurObj);
      let oldRecurData = await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        recurring_rule: [cardItem?.recurring_rule],
        is_reconciled: false,
      });
      let array = [];
      oldRecurData?.forEach((element) => {
        let obj = {
          title: `Copy of ${element?.title}`,
          state: initialData?.bookedState.includes(element?.state)
            ? "Open"
            : element?.state,
          scenario: element?.scenario,
          note: element?.note,
          due_date: element?.due_date,
          invoice_date: element?.invoice_date,
          tax: element?.tax,
          category: element?.category,
          gross_value: element?.gross_value,
          data_source: element?.data_source,
          contact: element?.contact,
          cost_unit: element?.cost_unit,
          source: 1,
          position: element?.position,
          currency: element?.currency,
          recurring_rule: recurring_rule,
        };
        array.push(obj);
      });
      setTimeout(() => {
        addBatch(array);
      }, 1000);
    }
  };

  const onClickCardReconcile = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (cardItem?.reconciled) {
        const obj = {
          reconciled: null,
        };
        updateFlag(obj);
      } else {
        setOpen(true);
      }
    }
  };

  const onCloseDelete = () => {
    dispatch(setPopupStatus3(null));
  };

  const onOkDelete = async () => {
    onCloseDelete();
    if (deleteType.current === "single") {
      deleteCardByIdApi(cardItem.uuid);
    }
    if (deleteType.current === "recurring") {
      dispatch(setLoading(true));
      let oldRecurData = await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        recurring_rule: [cardItem?.recurring_rule],
        is_reconciled: false,
      });
      const recurring_rules = store.getState().globalSlice?.recurring_rules;
      const data = recurring_rules?.filter(
        (o1) => o1?.uuid !== cardItem?.recurring_rule
      );
      dispatch(setRecurring_rules(data));
      deleteBatch(oldRecurData);
    }
  };

  const onClickSplit = () => {
    splitModalType.current =
      cardItem?.children?.length > 0 || cardItem.parent ? "edit" : "add";
    setIsSplitOpen(true);
  };

  const handleCloseSplit = (type) => {
    setIsSplitOpen(false);
    if (!type) onClose(true);
  };

  const onAdd = () => {
    if (tab === "single") {
      add_transaction();
    } else {
      onSaveRecurrence({ doClose: true });
    }
  };

  const onClickMenu = (e) => {
    e.stopPropagation();
    setAnchorElMenu(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorElMenu(null);
  };

  const onClickItem = async (e, value, item) => {
    handleClose();
    if (value === 1) {
      onCloseTransactionEdit({ isPastIncluded: true });
    }
  };

  const btnBackgroundColor = Color.tailwind.grey[200];
  return (
    <Box
      sx={{
        width: "45rem",
        py: "1.5rem",
        justifyContent: "space-between",
        backgroundColor: Color.FooterBG,
        position: "fixed",
        bottom: 0,
        right: "0",
        zIndex: 6,
      }}
    >
      {open && (
        <ReconcileOverlay
          setOpen={setOpen}
          open={open}
          itemToBeReconcile={cardItem}
          isTransactionFormUpdated={isTransactionFormUpdated}
        />
      )}
      {isSplitOpen && (
        <SplitModal
          handleClose={handleCloseSplit}
          open={isSplitOpen}
          itemToBeSplit={cardItem}
          modalType={splitModalType.current}
        />
      )}
      {modalType === "edit" ? (
        <ActionViewWrapper
          height="2.6rem"
          iconMR={"0.5rem"}
          width="fit-content"
          wrapperBackgroundColor={"transparent !important"}
          btnBackgroundColor={btnBackgroundColor}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            maxWidth: "97%",
            mb:
              modalType === "edit" && cardItem?.recurring_rule && showSaved
                ? "2rem"
                : "4rem",
            " .actionBtn": {
              paddingRight: "1.5rem",
              borderRadius: theme.borderRadius.main,
            },
          }}
        >
          <Stack sx={{ gap: "0.5rem", ml: "1.25rem" }}>
            <Stack direction="row" alignItems={"center"} sx={{ gap: "0.5rem" }}>
              <TailwindButton
                disable={!showSaved}
                text={
                  showSaved
                    ? t("transaction-edit-form-saved-button")
                    : t("Update")
                }
                sx={{ fontSize: "0.8rem", ml: 0 }}
                onClick={showSaved ? onCloseTransactionEdit : undefined}
              />

              <TailwindButton
                type="cancel"
                text={t("Cancel")}
                sx={{
                  fontSize: "0.8rem",
                  ml: 0,
                  backgroundColor: theme.palette.color.slate[200],
                  "&:hover": {
                    backgroundColor: theme.palette.color.slate[300],
                  },
                }}
                onClick={onCancel}
              />
            </Stack>

            {modalType === "edit" && cardItem?.recurring_rule && showSaved ? (
              <TailwindButton
                variant="outlined"
                text={t("transaction-edit-form-past-button")}
                color={theme.palette.primary.main}
                sx={{
                  fontSize: "0.8rem",
                  ml: 0,
                }}
                onClick={() => onCloseTransactionEdit({ isPastIncluded: true })}
              />
            ) : null}
            {/* {modalType === "edit" && cardItem?.recurring_rule && showSaved ? (
              <span>
                <MenuView
                  anchorEl={anchorElMenu}
                  open={Boolean(anchorElMenu)}
                  options={menuOptions}
                  handleClose={handleClose}
                  onClickItem={onClickItem}
                />
                <IconButton
                  aria-label="more"
                  id="long-button"
                  onClick={onClickMenu}
                  sx={{ background: "transparent !important", mr: 1 }}
                >
                  <MoreVertIcon
                    sx={{
                      color: "color.slate.600",
                    }}
                  />
                </IconButton>
              </span>
            ) : null} */}
          </Stack>

          {cardItem?.recurring_rule ? (
            <Box
              size="small"
              sx={{
                display: "flex",
                gap: "0.5rem",
                borderRadius: "20px",
              }}
            >
              {isReconcileAllow && (
                <ActionButton
                  onClick={onClickCardReconcile}
                  isHighlighted={cardItem?.reconciled}
                  label={
                    cardItem?.reconciled ? t("Reconciled") : t("Reconcile")
                  }
                  icon={<CompareArrowsIcon />}
                />
              )}

              {cardItem?.state === "Booked" &&
              cardItem?.recurring_rule ? null : (
                <ActionButton
                  onClick={onClickRecurClone}
                  label={"Duplicate"}
                  icon={<ContentCopyIcon />}
                  tooltip={
                    tab === "employee"
                      ? "Duplicate Staff Action Tooltip"
                      : "Duplicate Recurring Sequence"
                  }
                />
              )}

              {!initialData.bookedState.includes(cardItem?.state) && (
                <ActionButton
                  onClick={onDeleteRecurrence}
                  label={"Delete"}
                  icon={<DeleteSweepIcon />}
                  tooltip={
                    tab === "employee"
                      ? "Delete Staff Action Tooltip"
                      : "Delete recurring Transactions"
                  }
                />
              )}
            </Box>
          ) : (
            <Box
              size="small"
              sx={{
                display: "flex",
                maxWidth: "fit-content",
                borderRadius: "20px",
                flexWrap: "wrap",
                gap: "0.5rem",
                ml: "2rem",
              }}
            >
              {cardItem?.source === 1 || cardItem.source === 2 ? (
                <ActionButton
                  onClick={onClickSplit}
                  isHighlighted={cardItem?.parent}
                  label={cardItem?.parent ? "Merge" : "Split"}
                  icon={<CallSplitIcon />}
                />
              ) : null}

              {isReconcileAllow && (
                <ActionButton
                  onClick={onClickCardReconcile}
                  isHighlighted={cardItem?.reconciled}
                  label={
                    cardItem?.reconciled ? t("Reconciled") : t("Reconcile")
                  }
                  icon={<CompareArrowsIcon />}
                />
              )}

              <ActionButton
                onClick={onClickCardClone}
                label={"Duplicate Planning"}
                icon={<ContentCopyIcon />}
              />

              {!disabled && (
                <ActionButton
                  onClick={onClickCardDelete}
                  label={"Delete Planning"}
                  icon={<DeleteIcon />}
                />
              )}
            </Box>
          )}
        </ActionViewWrapper>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          backgroundColor={"inherit"}
        >
          <Box backgroundColor={"inherit"}>
            <TailwindButton
              loading={loadingAdd}
              text={t("Add")}
              sx={{ fontSize: "1rem" }}
              onClick={onAdd}
            />
            <TailwindButton
              type="cancel"
              text={t("Cancel")}
              sx={{ fontSize: "1rem" }}
              onClick={onCloseTransactionEdit}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ActionButtonView;
