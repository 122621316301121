import { Tooltip, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import {
  getAllEmployeesQueryKey,
  getTransactionByParams,
} from "../Helper/data";
import { queryClient } from "../App";

const SubscriptionBadge = ({ type, ds_type, sx }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  //redux
  const dataSetList = useSelector((state) => state.boardSlice.dataSetList);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );

  const userList = queryClient.getQueryData(["users"]);
  const StaffList = useQuery({
    queryKey: getAllEmployeesQueryKey(),
    queryFn: async ({ signal }) => {
      const results = await getTransactionByParams({
        endpoint: "recurring_rules",
        contact_type: 3,
        page_size: 1,
        page: 1,
        config: {
          signal,
        },
      });
      if (results) {
        return results;
      }
    },
    backgroundFetch: true,
    cacheTime: 10000,
    refetchOnMount: true,
    priority: 1,
  });
  const StaffListCount = StaffList?.data?.count || 0;
  const isFetching = StaffList?.isFetching;
  const onClick = () => {
    navigate("/settings/subscription");
  };

  let text = "";
  let tooltip = "";
  let color = "slate";
  let total = 0;
  let used = 0;

  if (type === "dataset") {
    text = `Organizations Limit`;
    total = subscription?.dataset_limit || 0;
    used = dataSetList?.length || 0;
  }
  if (type === "employee") {
    text = `Employees Limit`;
    total = subscription?.employee_limit || 0;
    used = StaffListCount;
  }
  if (type === "users") {
    text = `Users Limit`;
    const activeUserList = userList?.filter((o1) => o1.is_active);
    total = subscription?.user_limit || 0;
    used = activeUserList?.length || 0;
  }
  if (type === "data_source") {
    used =
      dataSource?.filter(
        (o1) => ds_type?.includes(o1.type) && !o1.internal_dataset
      )?.length || 0;
    if (ds_type?.includes(1)) {
      total = subscription?.offline_data_source_limit || 0;
      text = "Offline DS Limit";
    }
    if (ds_type?.includes(12)) {
      total = subscription?.online_data_source_limit || 0;
      text = "Online DS Limit";
    }
    if (ds_type?.includes(19)) {
      total = subscription?.integration_data_source_limit || 0;
      // total = 7;
      text = "Integrations DS Limit";
    }
  }
  if (used >= total) {
    color = "red";
  }
  return (
    <Tooltip title={t(tooltip)} placement="top" arrow followCursor>
      <Button
        onClick={onClick}
        variant="caption"
        loading={isFetching}
        fontWeight={"fontWeightMediumBold"}
        sx={{
          cursor: "pointer",
          borderRadius: theme.borderRadius.main,
          backgroundColor:
            theme.palette.color[color][color === "red" ? 50 : 100],
          color: theme.palette.color[color][500],
          px: "1rem",
          py: "0.5rem",
          ...sx,
        }}
      >
        {`${used}/${total} ${t(text)}`}
      </Button>
    </Tooltip>
  );
};

export default SubscriptionBadge;
